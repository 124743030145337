import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  TextField,
  Button,
  Checkbox,
  Paper,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom"; // Импортируем useNavigate из React Router

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const textFieldStyles = {
  pr: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: 1.5,
    fontSize: "12px",
  },
  width: "100%",
};

const LotsAdmin = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();
  const [action, setAction] = React.useState("");

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  const handleAddNewLot = () => {
    navigate("/admin/add-lot");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const lots = [
    // Пример данных
    {
      id: "135467880",
      product: "Iphone 14 pro",
      category: "Смартфоны",
      startDate: "26.08.2024 09:45",
      endDate: "26.08.2024 09:45",
      startPrice: "34 500 тг",
      increment: "500-5000 тг",
      status: "Завершен",
    },
    {
      id: "135467880",
      product: "Iphone 14 pro",
      category: "Смартфоны",
      startDate: "26.08.2024 09:45",
      endDate: "26.08.2024 09:45",
      startPrice: "34 500 тг",
      increment: "500-5000 тг",
      status: "Завершен",
    },
    {
      id: "135467880",
      product: "Iphone 14 pro",
      category: "Смартфоны",
      startDate: "26.08.2024 09:45",
      endDate: "26.08.2024 09:45",
      startPrice: "34 500 тг",
      increment: "500-5000 тг",
      status: "Завершен",
    },
    {
      id: "135467880",
      product: "Iphone 14 pro",
      category: "Смартфоны",
      startDate: "26.08.2024 09:45",
      endDate: "26.08.2024 09:45",
      startPrice: "34 500 тг",
      increment: "500-5000 тг",
      status: "Завершен",
    },
    {
      id: "135467880",
      product: "Iphone 14 pro",
      category: "Смартфоны",
      startDate: "26.08.2024 09:45",
      endDate: "26.08.2024 09:45",
      startPrice: "34 500 тг",
      increment: "500-5000 тг",
      status: "Завершен",
    },
    {
      id: "135467880",
      product: "Iphone 14 pro",
      category: "Смартфоны",
      startDate: "26.08.2024 09:45",
      endDate: "26.08.2024 09:45",
      startPrice: "34 500 тг",
      increment: "500-5000 тг",
      status: "Завершен",
    },
  ];

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedLots = lots.slice(startIndex, endIndex);

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
          Лоты
        </Typography>
        <Button
          variant="contained"
          onClick={handleAddNewLot}
          sx={{
            background:
              "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
            boxShadow: "none",
            borderRadius: "10px",
            p: "14px 34px",
            fontSize: "14px",
            fontWeight: 400,
            textTransform: "none",
            mb: 4,
          }}>
          Добавить товар
        </Button>
      </Box>

      <Box sx={{ display: "flex", gap: 1.5 }}>
        <TextField
          select
          value={action}
          onChange={handleActionChange}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              borderColor: "#89939E",
              background: "#FFFFFF",
              "& fieldset": {
                borderColor: "#89939E",
              },
            },
            "& .MuiInputBase-input": {
              py: "10px",
            },
            minWidth: 180,
          }}
          displayEmpty
          SelectProps={{
            displayEmpty: true,
            renderValue: (value) => value || "Действие",
          }}>
          <MenuItem value="Действие 1">Действие 1</MenuItem>
          <MenuItem value="Действие 2">Действие 2</MenuItem>
          <MenuItem value="Действие 3">Действие 3</MenuItem>
        </TextField>
        <Button
          variant="contained"
          sx={{
            background:
              "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
            boxShadow: "none",
            borderRadius: "6px",
            p: "8px 24px",
            fontSize: "14px",
            fontWeight: 400,
            textTransform: "none",
            mb: 4,
          }}>
          Применить
        </Button>
      </Box>
      <Table sx={{ minWidth: 650, mb: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                p: 0,
                borderBottom: "1px solid #ABBED1",
                width: 50,
              }}>
              <Checkbox sx={{ pt: 4 }} />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 140,
              }}>
              ID
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Товар
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Категория
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Дата начала
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Дата завершения
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Стартовая цена
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Шаг увеличения
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 0,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Статус
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedLots.map((lot, index) => (
            <TableRow
              key={index}
              sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}>
              <TableCell sx={{ borderBottom: "none", p: 0 }}>
                <Checkbox />
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot.id}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot.product}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot.category}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot.startDate}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot.endDate}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot.startPrice}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{lot.increment}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>
                <Box
                  sx={{
                    backgroundColor:
                      lot.status === "Завершен"
                        ? "#D9F8E5"
                        : lot.status === "В процессе"
                        ? "#FEEADD"
                        : "transparent",
                    borderRadius: "8px",
                    p: "5px 10px",
                    textAlign: "center",
                    width: "fit-content",
                  }}>
                  {lot.status}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Количество элементов:</Typography>
          <FormControl variant="outlined" sx={{ ml: 2, minWidth: 60 }}>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              displayEmpty
              inputProps={{ "aria-label": "Items per page" }}
              sx={{ height: "35px", borderRadius: 2 }}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <StyledPagination
          count={Math.ceil(lots.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Paper>
  );
};

export default LotsAdmin;
