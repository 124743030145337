import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Checkbox,
  MenuItem,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  styled,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-of-type": {
    width: "5%", // Устанавливаем меньшую ширину для первого столбца
  },
  "&:nth-of-type(2)": {
    width: "15%", // Устанавливаем ширину для второго столбца
  },
  "&:not(:first-of-type):not(:nth-of-type(2))": {
    width: "20%", // Устанавливаем равную ширину для остальных столбцов
  },
  borderBottom: "1px solid #E3EEFF", // Устанавливаем цвет нижней границы для тела таблицы
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  "&:first-of-type": {
    width: "5%", // Устанавливаем меньшую ширину для первого столбца
  },
  "&:nth-of-type(2)": {
    width: "15%", // Устанавливаем ширину для второго столбца
  },
  "&:not(:first-of-type):not(:nth-of-type(2))": {
    width: "20%", // Устанавливаем равную ширину для остальных столбцов
  },
  borderBottom: "none", // Убираем бордер для заголовков таблицы
}));

const StyledTableRow = styled(TableRow)(({ odd }) => ({
  backgroundColor: odd ? "#ffffff" : "#F5F7FA",
}));

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px", // Округлость углов
    border: "1px solid #89939E", // Цвет границы
    backgroundColor: "transparent", // Цвет фона
    "& fieldset": {
      border: "none", // Убираем границу
    },
  },
  "& .MuiInputBase-input": {
    py: "10px", // Отступы внутри поля ввода
  },
};

const participants = [
  {
    id: 1,
    username: "Ivan123",
    bidsCount: 2,
    bid: "75 000тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
  {
    id: 2,
    username: "Maria_K",
    bidsCount: 1,
    bid: "74 500тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
  {
    id: 3,
    username: "Petr22",
    bidsCount: 5,
    bid: "68 000тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
  {
    id: 4,
    username: "Anna.L",
    bidsCount: 2,
    bid: "67 000тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
  {
    id: 5,
    username: "Sergei-56",
    bidsCount: 6,
    bid: "66 000тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
  {
    id: 6,
    username: "Olga99",
    bidsCount: 2,
    bid: "65 000тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
  {
    id: 7,
    username: "Dmitrii_Vasilievich",
    bidsCount: 1,
    bid: "64 000тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
  {
    id: 8,
    username: "Ekaterina_K",
    bidsCount: 2,
    bid: "63 000тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
  {
    id: 9,
    username: "Aleksandr-KK",
    bidsCount: 2,
    bid: "61 000тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
  {
    id: 10,
    username: "Natalia-77",
    bidsCount: 2,
    bid: "60 000тг",
    email: "mail@mail.ru",
    phone: "+7 (707) 123-45-67",
  },
];

const AddNewLot = ({ handleBack }) => {
  const [productImage, setProductImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [category, setCategory] = useState("");

  const [description, setDescription] = useState("");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const [showSliderImage, setShowSliderImage] = useState(false);

  const handleProductImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProductImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleGalleryImagesChange = (event) => {
    if (event.target.files) {
      const newImages = Array.from(event.target.files).map((file) => URL.createObjectURL(file));
      setGalleryImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  const handleRemoveProductImage = () => {
    setProductImage(null);
  };

  const handleRemoveGalleryImage = (index) => {
    setGalleryImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleSliderCheckboxChange = (event) => {
    setShowSliderImage(event.target.checked);
  };

  return (
    <Paper sx={{ borderRadius: "10px", boxShadow: "none", p: 3 }}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          color: "#3083FF",
          borderColor: "#3083FF",
          fontWeight: 400,
          "&:hover": {
            borderColor: "#3083FF",
            backgroundColor: "rgba(48, 131, 255, 0.08)",
          },
        }}>
        Назад
      </Button>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, my: 3 }}>
        Новый лот
      </Typography>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Наименование</Typography>
            <TextField
              placeholder="Наименование"
              variant="outlined"
              fullWidth
              sx={textFieldStyles}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Код лота</Typography>
            <TextField placeholder="Код лота" variant="outlined" fullWidth sx={textFieldStyles} />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Категория</Typography>
            <TextField
              select
              value={category}
              onChange={handleCategoryChange}
              variant="outlined"
              sx={{ ...textFieldStyles, width: "100%" }}
              displayEmpty
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) => value || "Категория",
              }}>
              <MenuItem value="Категория 1">Категория 1</MenuItem>
              <MenuItem value="Категория 2">Категория 2</MenuItem>
              <MenuItem value="Категория 3">Категория 3</MenuItem>
            </TextField>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Начальная стоимость
              </Typography>
              <TextField
                placeholder="Начальная стоимость"
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Базовый шаг увеличения
              </Typography>
              <TextField
                placeholder="Базовый шаг увеличения"
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                Премиум шаг увеличения
              </Typography>
              <TextField
                placeholder="Премиум шаг увеличения"
                variant="outlined"
                fullWidth
                sx={textFieldStyles}
              />
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Дата начала</Typography>
            <TextField
              placeholder="Дата начала"
              variant="outlined"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={textFieldStyles}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
              Краткое описание
            </Typography>
            <TextField
              placeholder="Краткое описание"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px", // Округлость углов
                  borderColor: "#89939E", // Цвет границы
                  pb: 4,
                  "& fieldset": {
                    borderColor: "#89939E", // Цвет границы
                  },
                },
              }}
              inputProps={{ maxLength: 200 }}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Описание</Typography>
            <ReactQuill
              value={description}
              onChange={handleDescriptionChange}
              theme="snow"
              placeholder="Описание"
            />
          </Box>
        </Box>
        <Box sx={{ width: 360, display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            sx={{
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              p: "14px 34px",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            Опубликовать
          </Button>
          <Box display={"flex"}>
            <Typography sx={{ fontSize: "14px", fontWeight: 400, mr: 0.5 }} variant="h6">
              Статус:
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }} variant="h6">
              Новый
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ border: "1px solid #8C8C8C", gap: 2, p: 2, borderRadius: "5px" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500, mb: 2 }} variant="h6">
                Изображение товара
              </Typography>
              {!productImage ? (
                <Button
                  variant="text"
                  component="label"
                  sx={{
                    p: 0,
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#3083FF",
                    textTransform: "none",
                  }}>
                  Задать изображение
                  <input type="file" hidden onChange={handleProductImageChange} />
                </Button>
              ) : (
                <Box sx={{ position: "relative" }}>
                  <img
                    src={productImage}
                    alt="Product"
                    style={{ width: "100%", borderRadius: "5px" }}
                  />
                  <IconButton
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      borderRadius: "50%",
                    }}
                    onClick={handleRemoveProductImage}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box sx={{ border: "1px solid #8C8C8C", gap: 2, p: 2, borderRadius: "5px" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500, mb: 2 }} variant="h6">
                Галерея изображений
              </Typography>
              <Button
                variant="text"
                component="label"
                sx={{
                  p: 0,
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#3083FF",
                  textTransform: "none",
                }}>
                Загрузить изображения
                <input type="file" multiple hidden onChange={handleGalleryImagesChange} />
              </Button>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {galleryImages.map((image, index) => (
                  <Box key={index} sx={{ position: "relative" }}>
                    <img
                      src={image}
                      alt={`Gallery ${index}`}
                      style={{ width: "100px", borderRadius: "5px" }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleRemoveGalleryImage(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
            <FormControlLabel
              control={<Checkbox checked={showSliderImage} onChange={handleSliderCheckboxChange} />}
              label="Использовать на слайдере"
            />
            {showSliderImage && (
              <Box sx={{ border: "1px solid #8C8C8C", gap: 2, p: 2, borderRadius: "5px" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500, mb: 2 }} variant="h6">
                  Изображение для слайдера (1920px *500px)
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    p: 0,
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#3083FF",
                    textTransform: "none",
                  }}>
                  Задать изображение
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography sx={{ fontSize: "28px", fontWeight: 600 }} variant="h5">
          Участники
        </Typography>
        {/* Здесь будут отображаться участники */}
        <Typography variant="h6" sx={{ mt: 3, fontSize: "20px", fontWeight: 600 }}>
          Покупатель
        </Typography>
        <Table sx={{ mb: 3 }}>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>№</StyledTableHeadCell>
              <StyledTableHeadCell>Пользователь</StyledTableHeadCell>
              <StyledTableHeadCell>Количество ставок</StyledTableHeadCell>
              <StyledTableHeadCell>Ставка</StyledTableHeadCell>
              <StyledTableHeadCell>email</StyledTableHeadCell>
              <StyledTableHeadCell>Телефон</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow odd={true}>
              <StyledTableCell>{participants[0].id}</StyledTableCell>
              <StyledTableCell>{participants[0].username}</StyledTableCell>
              <StyledTableCell>{participants[0].bidsCount}</StyledTableCell>
              <StyledTableCell>{participants[0].bid}</StyledTableCell>
              <StyledTableCell>{participants[0].email}</StyledTableCell>
              <StyledTableCell>
                <a href={`tel:${participants[0].phone}`} style={{ color: "#3083FF" }}>
                  {participants[0].phone}
                </a>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
        <Typography variant="h6" sx={{ mt: 3, mb: 1, fontSize: "20px", fontWeight: 600 }}>
          ТОП 10 высоких ставок
        </Typography>
        <Table sx={{ mb: 3 }}>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>№</StyledTableHeadCell>
              <StyledTableHeadCell>Пользователь</StyledTableHeadCell>
              <StyledTableHeadCell>Количество ставок</StyledTableHeadCell>
              <StyledTableHeadCell>Ставка</StyledTableHeadCell>
              <StyledTableHeadCell>email</StyledTableHeadCell>
              <StyledTableHeadCell>Телефон</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.slice(0, 10).map((participant, index) => (
              <StyledTableRow key={participant.id} odd={index % 2 !== 0}>
                <StyledTableCell>{participant.id}</StyledTableCell>
                <StyledTableCell>{participant.username}</StyledTableCell>
                <StyledTableCell>{participant.bidsCount}</StyledTableCell>
                <StyledTableCell>{participant.bid}</StyledTableCell>
                <StyledTableCell>{participant.email}</StyledTableCell>
                <StyledTableCell>
                  <a href={`tel:${participant.phone}`} style={{ color: "#3083FF" }}>
                    {participant.phone}
                  </a>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="h6" sx={{ mt: 3, mb: 1, fontSize: "20px", fontWeight: 600 }}>
          ТОП 10 активных пользователей
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>№</StyledTableHeadCell>
              <StyledTableHeadCell>Пользователь</StyledTableHeadCell>
              <StyledTableHeadCell>Количество ставок</StyledTableHeadCell>
              <StyledTableHeadCell>Ставка</StyledTableHeadCell>
              <StyledTableHeadCell>email</StyledTableHeadCell>
              <StyledTableHeadCell>Телефон</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.slice(0, 10).map((participant, index) => (
              <StyledTableRow key={participant.id} odd={index % 2 !== 0}>
                <StyledTableCell>{participant.id}</StyledTableCell>
                <StyledTableCell>{participant.username}</StyledTableCell>
                <StyledTableCell>{participant.bidsCount}</StyledTableCell>
                <StyledTableCell>{participant.bid}</StyledTableCell>
                <StyledTableCell>{participant.email}</StyledTableCell>
                <StyledTableCell>
                  <a href={`tel:${participant.phone}`} style={{ color: "#3083FF" }}>
                    {participant.phone}
                  </a>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default AddNewLot;
