const SlidesData = [
  {
    title: "Наушники SENHESER 5000",
    startDate: "05.03.2024",
    startPrice: "5 000 тг",
  },
  {
    title: "Другие Наушники",
    startDate: "06.03.2024",
    startPrice: "6 000 тг",
  },
];

export default SlidesData;
