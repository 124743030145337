const categories = [
  "Iphone",
  "Смартфоны",
  "Ноутбуки",
  "Аудиотехника",
  "Бытовая техника",
  "Техника для кухни",
];

const lotStatus = ["Активные", "Планируемые", "Недавно завершенные"];

export { categories, lotStatus };
