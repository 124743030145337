// MyShopping.jsx
import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const ITEMS_PER_PAGE = 9;

const MyShopping = () => {
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const purchases = [
    {
      lot: "135467880",
      date: "26.08.2024",
      status: "Не оплачен",
      product: "Iphone 14 pro",
      amount: "234 500 тг",
      paid: false,
    },
    {
      lot: "135467880",
      date: "26.08.2024",
      status: "Оплачен",
      product: "Iphone 14 pro",
      amount: "234 500 тг",
      paid: true,
    },
    {
      lot: "135467880",
      date: "26.08.2024",
      status: "Не оплачен",
      product: "Iphone 14 pro",
      amount: "234 500 тг",
      paid: false,
    },
    {
      lot: "135467880",
      date: "26.08.2024",
      status: "Оплачен",
      product: "Iphone 14 pro",
      amount: "234 500 тг",
      paid: true,
    },
    {
      lot: "135467880",
      date: "26.08.2024",
      status: "Не оплачен",
      product: "Iphone 14 pro",
      amount: "234 500 тг",
      paid: false,
    },
    {
      lot: "135467880",
      date: "26.08.2024",
      status: "Оплачен",
      product: "Iphone 14 pro",
      amount: "234 500 тг",
      paid: true,
    },
    {
      lot: "135467880",
      date: "26.08.2024",
      status: "Не оплачен",
      product: "Iphone 14 pro",
      amount: "234 500 тг",
      paid: false,
    },
    {
      lot: "135467880",
      date: "26.08.2024",
      status: "Оплачен",
      product: "Iphone 14 pro",
      amount: "234 500 тг",
      paid: true,
    },
  ];

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedPurchases = purchases.slice(startIndex, endIndex);

  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
        История покупок
      </Typography>
      <Table sx={{ minWidth: 650, my: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              № Лот
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              Дата
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              Статус
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              Товар
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
              }}>
              Сумма
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedPurchases.map((purchase, index) => (
            <TableRow
              key={index}
              sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}>
              <TableCell sx={{ borderBottom: "none", py: 1.5 }}>{purchase.lot}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5 }}>{purchase.date}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5 }}>
                <Box
                  sx={{
                    backgroundColor: purchase.paid ? "#D9F8E5" : "#FDE6E3",
                    borderRadius: "8px",
                    p: "5px 10px",
                    textAlign: "center",
                    width: "fit-content",
                  }}>
                  {purchase.status}
                </Box>
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5 }}>{purchase.product}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5 }}>{purchase.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(purchases.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default MyShopping;
