import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Pagination,
  Typography,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Cards from "../../DB/Cards";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const StyledIconButton = styled(IconButton)({
  "&::before, &::after": {
    display: "none",
  },
});

const ITEMS_PER_PAGE = 12;

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const LotGrid = () => {
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState(Array(Cards.length).fill(false));
  const [page, setPage] = useState(1);

  const handleFavoriteClick = (index) => {
    const newFavorite = [...favorite];
    newFavorite[index] = !newFavorite[index];
    setFavorite(newFavorite);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleParticipateClick = (item) => {
    navigate(`/lots/smartphones/iphone-15-pro-max`);
  };

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedCards = Cards.slice(startIndex, endIndex);

  return (
    <Box sx={{ mb: 6 }}>
      <Grid container spacing={2}>
        {paginatedCards.map((item, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <Card
              sx={{
                borderRadius: 6,
                boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
                mb: 3,
              }}>
              <Box
                sx={{
                  position: "absolute",
                  m: 2,
                }}>
                <StyledIconButton
                  sx={{ color: "white", width: 32, height: 32 }}
                  onClick={() => handleFavoriteClick(index)}
                  aria-label="favorite">
                  {favorite[index] ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
                </StyledIconButton>
              </Box>
              <CardMedia
                sx={{ height: 170, borderRadius: 6 }}
                image={item.image}
                title={item.title}
              />
              <CardContent sx={{ pt: 0 }}>
                <Box sx={{ p: "11px 0" }}>
                  <Typography
                    sx={{ fontSize: "20px", fontWeight: 600, pt: 1 }}
                    variant="subtitle1"
                    align="center">
                    {item.title}
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "16px",
                        }}
                        variant="caption"
                        align="center">
                        Начнется через
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                        variant="caption"
                        align="center">
                        {item.time}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Button
                  fullWidth
                  sx={{
                    background:
                      "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                    height: "32px",
                    boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
                    borderRadius: 1.5,
                    mt: 1,
                  }}
                  variant="contained"
                  onClick={() => handleParticipateClick(item)}>
                  Принять участие
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(Cards.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default LotGrid;
