import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const data = [
  { rank: 1, login: "Ivan123", bid: 2, amount: "75 000тг", city: "Павлодар" },
  { rank: 2, login: "Maria_K", bid: 2, amount: "74 500тг", city: "Шымкент" },
  { rank: 3, login: "Petr22", bid: 5, amount: "68 000тг", city: "Актобе" },
  { rank: 4, login: "Anna.L", bid: 3, amount: "67 000тг", city: "Астана" },
  { rank: 5, login: "Sergei-56", bid: 3, amount: "66 000тг", city: "Астана" },
  { rank: 6, login: "Olga99", bid: 2, amount: "65 000тг", city: "Актау" },
  { rank: 7, login: "Dmitrii_Vasiliev", bid: 4, amount: "64 000тг", city: "Экибастуз" },
  { rank: 8, login: "Ekaterina_K", bid: 2, amount: "63 000тг", city: "Астана" },
  { rank: 9, login: "Aleksandr-KK", bid: 2, amount: "61 000тг", city: "Тараз" },
  { rank: 18, login: "Вы", bid: 3, amount: "75 000тг", city: "Павлодар" },
];

const TopBidsTable = () => {
  return (
    <Grid item xs={12} lg={3}>
      <Button
        variant="outlined"
        sx={{
          display: "block",
          mx: "auto",
          mb: 1.5,
          borderRadius: 2,
          textTransform: "none",
          borderColor: "#3083FF",
          color: "#3083FF",
          fontWeight: 400,
          width: "100%",
          fontSize: "14px",
        }}>
        ТОП 10 активных участников
      </Button>
      <Box
        sx={{
          border: "1px solid #E3EEFF",
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
        }}>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#E3EEFF",
            fontSize: "20px",
            p: 1,
            fontWeight: 600,
          }}>
          Топ 10 высоких ставок
        </Typography>
        <Typography
          sx={{
            p: 1.5,
            textAlign: "right",
            fontSize: "12px",
            fontWeight: 400,
            backgroundColor: "#FFF",
          }}>
          Пользователей: 1000
        </Typography>
        <TableContainer component={Paper} sx={{ p: 1, pt: 0 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 400,
                    fontSize: "10px",
                    padding: "3px",
                    borderBottom: "none",
                  }}>
                  №
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 400,
                    fontSize: "10px",
                    padding: "3px",
                    borderBottom: "none",
                  }}>
                  Логин
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 400,
                    fontSize: "10px",
                    padding: "3px",
                    borderBottom: "none",
                  }}>
                  Ставок
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 400,
                    fontSize: "10px",
                    padding: "3px",
                    borderBottom: "none",
                  }}>
                  Ставка
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 400,
                    fontSize: "10px",
                    padding: "3px",
                    borderBottom: "none",
                  }}>
                  Город
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={row.rank}
                  sx={{
                    backgroundColor: row.login === "Вы" ? "#E3FCEF" : "#FFF",
                    borderBottom: "1px solid #E3EEFF",
                  }}>
                  <TableCell sx={{ fontSize: "10px", padding: "5px" }}>{row.rank}</TableCell>
                  <TableCell sx={{ fontSize: "10px", padding: "5px" }}>{row.login}</TableCell>
                  <TableCell sx={{ fontSize: "10px", padding: "5px" }}>{row.bid}</TableCell>
                  <TableCell sx={{ fontSize: "10px", padding: "5px" }}>{row.amount}</TableCell>
                  <TableCell sx={{ fontSize: "10px", padding: "5px" }}>{row.city}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
};

export default TopBidsTable;
