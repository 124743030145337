import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  MenuItem,
  Grid,
} from "@mui/material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px", // Округлость углов
    borderColor: "#89939E", // Цвет границы
    "& fieldset": {
      borderColor: "#89939E", // Цвет границы
    },
  },
  "& .MuiInputBase-input": {
    py: "10px", // Отступы внутри поля ввода
  },
};

const Checkout = () => {
  const [deliveryMethod, setDeliveryMethod] = useState("Самовывоз с офиса г. Астана");

  const handleDeliveryChange = (event) => {
    setDeliveryMethod(event.target.value);
  };

  return (
    <Container className="container">
      <Header />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          px: 3,
          py: 5,
          my: 5,
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}>
        <Typography variant="h4" sx={{ fontSize: "36px", mb: 4, fontWeight: 600 }}>
          Оформление
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: 600 }}>
              Персональные данные
            </Typography>
            <Grid container spacing={3} sx={{ my: 3 }}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Email *</Typography>
                <TextField
                  placeholder="test@mail.com"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      border: "none",
                      backgroundColor: "#F5F7FA",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      py: "10px", // Отступы внутри поля ввода
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                  Телефон *
                </Typography>
                <TextField
                  placeholder="+7 (700) 654 32 84"
                  variant="outlined"
                  fullWidth
                  sx={textFieldStyles}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                  Фамилия *
                </Typography>
                <TextField placeholder="Тестов" variant="outlined" fullWidth sx={textFieldStyles} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Имя *</Typography>
                <TextField placeholder="Тест" variant="outlined" fullWidth sx={textFieldStyles} />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                  Отчество *
                </Typography>
                <TextField
                  placeholder="Отчество"
                  variant="outlined"
                  fullWidth
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                  Дата рождения *
                </Typography>
                <TextField
                  placeholder="25.04.1994"
                  variant="outlined"
                  fullWidth
                  sx={textFieldStyles}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TodayOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Typography variant="h6" sx={{ fontSize: "20px", mt: 3, mb: 2 }}>
              Доставка
            </Typography>
            <RadioGroup value={deliveryMethod} onChange={handleDeliveryChange}>
              <FormControlLabel
                value="Самовывоз с офиса г. Астана"
                control={<Radio />}
                label="Самовывоз с офиса г. Астана"
              />
              <Typography variant="body2" sx={{ ml: 4, mb: 2 }}>
                РК, 010000, г. Астана, ул. Кенесары 40, 4 этаж, офис 15
              </Typography>
              <FormControlLabel
                value="Доставка почтой по Казахстану"
                control={<Radio />}
                label="Доставка почтой по Казахстану"
              />
            </RadioGroup>
            {deliveryMethod === "Доставка почтой по Казахстану" && (
              <Box sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Страна *</Typography>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  placeholder="Страна"
                  sx={{ ...textFieldStyles, mb: 3 }}>
                  <MenuItem value="Казахстан">Казахстан</MenuItem>
                  {/* Добавьте другие страны по мере необходимости */}
                </TextField>
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Город *</Typography>
                <TextField
                  placeholder="Город"
                  variant="outlined"
                  fullWidth
                  sx={{ ...textFieldStyles, mb: 3 }}
                />
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Улица *</Typography>
                <TextField
                  placeholder="Улица"
                  variant="outlined"
                  fullWidth
                  sx={{ ...textFieldStyles, mb: 3 }}
                />
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                  Номер дома *
                </Typography>
                <TextField
                  placeholder="Номер дома"
                  variant="outlined"
                  fullWidth
                  sx={{ ...textFieldStyles, mb: 3 }}
                />
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                  Квартира/офис *
                </Typography>
                <TextField
                  placeholder="Квартира/офис"
                  variant="outlined"
                  fullWidth
                  sx={{ ...textFieldStyles, mb: 3 }}
                />
                <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
                  Примечание
                </Typography>
                <TextField
                  placeholder="Примечание"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px", // Округлость углов
                      borderColor: "#89939E", // Цвет границы
                      "& fieldset": {
                        borderColor: "#89939E", // Цвет границы
                      },
                    },
                    mb: 3,
                  }}
                  inputProps={{ maxLength: 200 }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}>
              <Box>
                <Typography variant="h6" sx={{ fontSize: "14px" }}>
                  Товар:
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Iphone 15 Pro 256Gb
                </Typography>
                <Typography variant="body2" sx={{ mt: 3, fontSize: "14px" }}>
                  Стоимость:
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  456 000 тг
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    background:
                      "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                    boxShadow: "none",
                    borderRadius: "10px",
                    py: "10px",
                    fontSize: "16px",
                    fontWeight: 400,
                    textTransform: "none",
                    mt: 2,
                  }}>
                  Оплатить
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
};

export default Checkout;
