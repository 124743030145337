import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  TextField,
  Button,
  Checkbox,
  Paper,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import AddNewCategory from "./AddNewCategory";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const textFieldStyles = {
  pr: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: 1.5,
    fontSize: "12px",
  },
  width: "100%",
};

const CategoriesAdmin = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showAddCategory, setShowAddCategory] = useState(false);

  const [action, setAction] = React.useState("");

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const handleAddCategory = () => {
    setShowAddCategory(true);
  };

  const handleBackToCategories = () => {
    setShowAddCategory(false);
  };

  const categories = [
    {
      id: "135467880",
      category: "Смартфоны",
      slag: "phones",
    },
    {
      id: "135467880",
      category: "Смартфоны",
      slag: "phones",
    },
    {
      id: "135467880",
      category: "Смартфоны",
      slag: "phones",
    },
    {
      id: "135467880",
      category: "Смартфоны",
      slag: "phones",
    },
    {
      id: "135467880",
      category: "Смартфоны",
      slag: "phones",
    },
    {
      id: "135467880",
      category: "Смартфоны",
      slag: "phones",
    },
  ];

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedCategories = categories.slice(startIndex, endIndex);

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none" }}>
      {showAddCategory ? (
        <AddNewCategory handleBack={handleBackToCategories} />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
              Категории
            </Typography>
            <Button
              variant="contained"
              onClick={handleAddCategory}
              sx={{
                background:
                  "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                boxShadow: "none",
                borderRadius: "10px",
                p: "14px 34px",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                mb: 4,
              }}>
              Добавить категорию
            </Button>
          </Box>

          <Box sx={{ display: "flex", gap: 1.5 }}>
            <TextField
              select
              value={action}
              onChange={handleActionChange}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  borderColor: "#89939E",
                  background: "#FFFFFF",
                  "& fieldset": {
                    borderColor: "#89939E",
                  },
                },
                "& .MuiInputBase-input": {
                  py: "10px",
                },
                minWidth: 180,
              }}
              displayEmpty
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) => value || "Действие",
              }}>
              <MenuItem value="Действие 1">Действие 1</MenuItem>
              <MenuItem value="Действие 2">Действие 2</MenuItem>
              <MenuItem value="Действие 3">Действие 3</MenuItem>
            </TextField>
            <Button
              variant="contained"
              sx={{
                background:
                  "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                boxShadow: "none",
                borderRadius: "6px",
                p: "8px 24px",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                mb: 4,
              }}>
              Применить
            </Button>
          </Box>
          <Table sx={{ minWidth: 650, mb: 4 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    p: 0,
                    borderBottom: "1px solid #ABBED1",
                    width: 40,
                    textAlign: "start",
                  }}>
                  <Checkbox sx={{ pt: 4 }} />
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    px: 0.5,
                    py: 1.5,
                    borderBottom: "1px solid #ABBED1",
                    width: 100,
                  }}>
                  ID
                  <TextField
                    placeholder="Поиск"
                    variant="outlined"
                    size="small"
                    sx={{ ...textFieldStyles, mt: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    px: 0.5,
                    py: 1.5,
                    borderBottom: "1px solid #ABBED1",
                    width: 240,
                  }}>
                  Категория
                  <TextField
                    placeholder="Поиск"
                    variant="outlined"
                    size="small"
                    sx={{ ...textFieldStyles, mt: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    px: 0.5,
                    py: 1.5,
                    borderBottom: "1px solid #ABBED1",
                    width: 240,
                  }}>
                  Slag
                  <TextField
                    placeholder="Поиск"
                    variant="outlined"
                    size="small"
                    sx={{ ...textFieldStyles, mt: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    px: 0.5,
                    py: 1.5,
                    borderBottom: "1px solid #ABBED1",
                    width: 240,
                  }}>
                  Изображение
                  <TextField
                    placeholder="Поиск"
                    variant="outlined"
                    size="small"
                    sx={{ ...textFieldStyles, mt: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCategories.map((category, index) => (
                <TableRow
                  key={index}
                  sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}>
                  <TableCell sx={{ borderBottom: "none", p: 0 }}>
                    <Checkbox />
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>{category.id}</TableCell>
                  <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>
                    {category.category}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}>
                    {category.slag}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none", py: 1.5, px: 0 }}></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>Количество элементов:</Typography>
              <FormControl variant="outlined" sx={{ ml: 2, minWidth: 60 }}>
                <Select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Items per page" }}
                  sx={{ height: "35px", borderRadius: 2 }}>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <StyledPagination
              count={Math.ceil(categories.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
              shape="rounded"
            />
          </Box>
        </>
      )}
    </Paper>
  );
};

export default CategoriesAdmin;
