import React from "react";
import { Box, TextField, Typography, Button, Grid } from "@mui/material";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const PersonalData = () => {
  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", mb: 4, fontWeight: 600 }}>
        Личные данные
      </Typography>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        Верификация
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          backgroundColor: "#e3f2fd",
          padding: 1,
          borderRadius: "10px",
          mb: 3,
          maxWidth: "fit-content",
        }}>
        <GppGoodOutlinedIcon sx={{ color: "#3083FF" }} />
        <Typography sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}>
          Верифицированный пользователь
        </Typography>
      </Box>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        Персональные данные
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            Электронная почта
          </Typography>
          <TextField
            placeholder="test@gmail.com"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Телефон</Typography>
          <TextField
            placeholder="8 701 768 23 45"
            variant="outlined"
            fullWidth
            sx={textFieldStyles}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Фамилия</Typography>
          <TextField placeholder="Тестов" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Имя</Typography>
          <TextField placeholder="Тест" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Отчество</Typography>
          <TextField placeholder="Отчество" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Дата рождения</Typography>
          <TextField placeholder="Тест" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        Пароль
      </Typography>
      <Button
        variant="outlined"
        sx={{
          borderColor: "#3083FF",
          color: "#3083FF",
          p: "12px 34px",
          fontSize: "16px",
          fontWeight: 400,
          textTransform: "none",
          borderRadius: "10px",
          mb: 2,
        }}>
        Сменить пароль
      </Button>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        Адрес
      </Typography>
      <Box sx={{ width: "100%", mb: 2 }}>
        <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Почтовый индекс</Typography>
        <TextField
          placeholder="Почтовый индекс"
          variant="outlined"
          fullWidth
          sx={textFieldStyles}
        />
      </Box>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Страна</Typography>
          <TextField placeholder="Казахстан" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Область</Typography>
          <TextField placeholder="Область" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Город</Typography>
          <TextField placeholder="Астана" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Улица</Typography>
          <TextField placeholder="Кенесары" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Номер дома</Typography>
          <TextField placeholder="40" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            Номер квартиры / офис
          </Typography>
          <TextField placeholder="15" variant="outlined" fullWidth sx={textFieldStyles} />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{
          background:
            "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
          boxShadow: "none",
          borderRadius: "10px",
          p: "12px 32px",
          fontSize: "16px",
          fontWeight: 400,
          textTransform: "none",
        }}>
        Сохранить
      </Button>
    </Box>
  );
};

export default PersonalData;
