import image from "../assets/images/icon_img_1.png";
import all from "../assets/images/all.svg";

const categoriesData = [
  { title: "Все", img: all },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
  { title: "Iphones", img: image },
];

export default categoriesData;
