import slide_img_1 from "../assets/images/Frame 72.png";
import slide_img_2 from "../assets/images/card_img_8.png";

const Images = [
  {
    src: slide_img_1,
    alt: "iPhone 15 Pro",
  },
  {
    src: slide_img_2,
    alt: "iPhone 15 Pro - Side View",
  },
  {
    src: slide_img_2,
    alt: "iPhone 15 Pro - Back View",
  },
  {
    src: slide_img_2,
    alt: "iPhone 15 Pro - Front View",
  },
];

export default Images;
