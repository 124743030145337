import React, { useState } from "react";
import { Box, Typography, Button, Container, Stepper, Step, StepLabel, Modal } from "@mui/material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Image from "../../assets/images/verification.png";

const CustomStepIcon = (props) => {
  const { active, icon } = props;

  return (
    <Box
      sx={{
        position: "relative",
        width: 44,
        height: 44,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: active ? "linear-gradient(180deg, #3083FF 0%, #73ABFF 100%)" : "#f0f2f5",
        borderColor: active ? "none" : "#ABBED1",
        borderWidth: active ? "none" : "2px",
        borderStyle: "solid",
      }}>
      <Typography
        sx={{
          color: active ? "#fff" : "#ABBED1",
          fontSize: "20px",
          fontWeight: 600,
          position: "absolute",
        }}>
        {icon}
      </Typography>
    </Box>
  );
};

const Verification = () => {
  const [showImage, setShowImage] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleShowImage = () => {
    setOpenModal(false);
    setShowImage(true);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Container className="container">
      <Header />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          maxWidth: "460px",
          margin: "0 auto",
          my: 5,
        }}>
        <Stepper
          activeStep={2}
          alternativeLabel
          sx={{
            width: "80%",
            "& .MuiStep-root": {
              p: 0,
            },
            "& .MuiStepLabel-root .MuiStepLabel-label": {
              display: "none",
            },
            "& .MuiStepConnector-root": {
              top: "21px",
              left: "calc(-50% + 22px)",
              right: "calc(50% + 20px)",
            },
            "& .MuiStepConnector-line": {
              borderColor: "#ABBED1",
              borderTopWidth: "2px",
              top: "50%",
            },
          }}>
          {[1, 2, 3].map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Typography variant="h4" sx={{ fontSize: "36px", my: 4, fontWeight: 600 }}>
          Верификация
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: "14px", mb: 4, fontWeight: 400, textAlign: "center" }}>
          Загрузите ваше удостоверение личности или паспорт это обязательно для участия в аукционах
        </Typography>
        {showImage ? (
          <Box sx={{ width: "100%", textAlign: "center", mb: 4 }}>
            <img src={Image} alt="Загруженное изображение" style={{ maxWidth: "100%" }} />
          </Box>
        ) : null}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 3,
            flexDirection: showImage ? "column" : "row",
          }}>
          <Button
            onClick={showImage ? handleOpenModal : null}
            variant={showImage ? "contained" : "outlined"}
            sx={{
              width: "100%",
              borderColor: showImage ? "none" : "#3083FF",
              color: showImage ? "#fff" : "#3083FF",
              background: showImage
                ? "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))"
                : "none",
              py: "14px",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
              borderRadius: "10px",
              boxShadow: "none",
            }}>
            {showImage ? "Загрузить другое" : "Позже"}
          </Button>
          <Button
            variant="contained"
            onClick={showImage ? null : handleOpenModal}
            sx={{
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              py: "14px",
              width: "100%",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            {showImage ? "Далее" : "Загрузить"}
          </Button>
        </Box>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Box
            sx={{
              boxShadow: 24,
              textAlign: "center",
              maxWidth: "500px",
              borderRadius: "10px",
            }}>
            <img
              src={Image}
              alt="Загруженное изображение"
              style={{ maxWidth: "100%", cursor: "pointer" }}
              onClick={handleShowImage}
            />
          </Box>
        </Modal>
      </Box>
      <Footer />
    </Container>
  );
};

export default Verification;
