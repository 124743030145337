import React, { useState } from "react";
import {
  Box,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Search as SearchIcon,
  PersonOutlined as PersonOutlinedIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  ExitToApp as ExitToAppIcon,
  Gavel as GavelIcon,
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import coin from "../../assets/images/coin 1.svg";
import star from "../../assets/images/Star 1.svg";

const menuItems = [
  { text: "Личные данные", icon: <PersonOutlinedIcon />, route: "/profile/личные-данные" },
  { text: "Баланс", icon: <AccountBalanceWalletOutlinedIcon />, route: "/profile/баланс" },
  { text: "Мои аукционы", icon: <GavelIcon />, route: "/profile/мои-аукционы" },
  { text: "Мои покупки", icon: <ShoppingCartOutlinedIcon />, route: "/profile/мои-покупки" },
  { text: "Избранное", icon: <FavoriteBorderOutlinedIcon />, route: "/profile/избранное" },
  { text: "Выход", icon: <ExitToAppIcon />, route: "/logout" },
];

const Authorized = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route) => {
    setAnchorEl(null);
    navigate(route);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
      <IconButton
        sx={{ color: "#3083FF", border: "1px solid #3083FF", borderRadius: 2, mr: "12px" }}>
        <SearchIcon sx={{ fontSize: "16px" }} />
      </IconButton>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#3083FF",
          padding: "4px 12px",
          borderRadius: 7,
          mr: "12px",
        }}>
        <Box>
          <img src={coin} alt="coin" />
        </Box>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "#fff", pl: 1 }}>
          1000
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #3083FF",
          borderRadius: 2,
          padding: "8px 12px",
          mr: "12px",
          color: "#3083FF",
          position: "relative",
          py: 0.5,
          cursor: "pointer",
        }}
        onClick={handleMenuOpen}>
        <PersonOutlinedIcon sx={{ fontSize: "16px" }} />
        <Typography sx={{ px: 1, fontSize: "14px", fontWeight: 400 }}>Petrov K</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            width: "24px",
            height: "24px",
          }}>
          <img src={star} alt="star" style={{ width: "80%", height: "100%" }} />
          <Typography
            sx={{
              position: "absolute",
              top: "55%",
              left: "40%",
              transform: "translate(-50%, -50%)",
              fontSize: "8px",
              fontWeight: "bold",
              color: "#fff",
            }}>
            10
          </Typography>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "190px",
            mt: 1.5,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          },
        }}>
        {menuItems.map((item, index) => (
          <MenuItem sx={{ py: 1 }} key={index} onClick={() => handleMenuItemClick(item.route)}>
            <ListItemIcon sx={{ minWidth: "32px" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Menu>

      <IconButton
        sx={{ color: "#3083FF", border: "1px solid #3083FF", borderRadius: 2, mr: "12px" }}>
        <NotificationsOutlinedIcon sx={{ fontSize: "16px" }} />
      </IconButton>

      <Button
        sx={{
          padding: "4px 22px",
          color: "#3083FF",
          fontSize: "14px",
          fontWeight: 400,
          border: "1px solid #3083FF",
          borderRadius: 2,
        }}
        variant="outlined">
        RU
      </Button>
    </Box>
  );
};

export default Authorized;
