import { Container } from "@mui/material";
import React from "react";
import Header from "../../components/Header/Header";
import "./Main.scss";
import Banner from "../../components/Banner/Banner";
import Categories from "../../components/Categoties/Categories";
import Nearest from "../../components/Nearest/Nearest";
import BuyNow from "../../components/BuyNow/BuyNow";
import Footer from "../../components/Footer/Footer";

export default function Main() {
  return (
    <Container className="container">
      <Header />
      <Banner />
      <Categories />
      <Nearest />
      <BuyNow />
      <Footer />
    </Container>
  );
}
