import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import AcquiringModal from "../AcquiringModal/AcquiringModal";

function FundsModal({ open, onClose }) {
  const [isAcquiringModalOpen, setAcquiringModalOpen] = useState(false);

  const handleOpenAcquiringModal = () => {
    setAcquiringModalOpen(true);
  };

  const handleCloseAcquiringModal = () => {
    setAcquiringModalOpen(false);
    onClose(); // Закрываем также FundsModal
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        PaperProps={{
          sx: {
            p: 3,
            borderRadius: 3,
            textAlign: "center",
            minWidth: 476,
          },
        }}>
        <DialogTitle id="modal-title" sx={{ fontSize: "20px", fontWeight: 600 }}>
          Недостаточно средств для участия.
        </DialogTitle>
        <DialogContent sx={{ fontSize: "16px", fontWeight: 600 }}>
          <Typography
            variant="body1"
            sx={{ fontSize: "16px", fontWeight: 400, color: "black" }}
            gutterBottom>
            Пополните баланс кошелька.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: 400, color: "black" }}>
            Стоимость участия 3000тг
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: "column",
            gap: 1,
            "& > :not(style) ~ :not(style)": {
              marginLeft: "0 !important",
            },
          }}>
          <Button
            variant="contained"
            onClick={handleOpenAcquiringModal}
            sx={{
              width: "100%",
              borderRadius: 2,
              py: 1,
              background: "linear-gradient(to right, #00c6ff, #0072ff)",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
              boxShadow: "none",
              m: 0,
            }}>
            Пополнить
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              width: "100%",
              borderRadius: 2,
              py: 1,
              borderColor: "#0072ff",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
              boxShadow: "none",
            }}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
      <AcquiringModal open={isAcquiringModalOpen} onClose={handleCloseAcquiringModal} />
    </>
  );
}

export default FundsModal;
