import React from "react";
import { Container, Grid } from "@mui/material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Crumbs from "../../components/Crums/Crums";
import Info from "../../components/Info/Info";
import ProductSlider from "../../components/ProductSlider/ProductSlider";
import Images from "../../DB/Images";
import Rate from "../../components/Rate/Rate";
import Description from "../../components/Description/Description";
import TopBidsTable from "../../components/TopBidsTable/TopBidsTable";

export default function Product() {
  return (
    <Container className="container">
      <Header />
      <Crumbs />
      <Info />
      <Grid container spacing={2}>
        <ProductSlider images={Images} />
        <Rate />
        <TopBidsTable />
      </Grid>
      <Description />
      <Footer />
    </Container>
  );
}
