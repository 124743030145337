import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

function DebitedModal({ open, onClose, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      PaperProps={{
        sx: {
          p: 3,
          borderRadius: 3,
          textAlign: "center",
          minWidth: 476,
        },
      }}>
      <DialogContent sx={{ fontSize: "20px", fontWeight: 600 }}>
        <Typography variant="body1" sx={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
          Будет списано 3000 тг с кошелька.
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "20px", fontWeight: 600, color: "black" }}>
          Вы уверены?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "column",
          gap: 1,
          "& > :not(style) ~ :not(style)": {
            marginLeft: "0 !important",
          },
        }}>
        <Button
          variant="contained"
          onClick={onConfirm}
          sx={{
            width: "100%",
            borderRadius: 2,
            py: 1,
            background: "linear-gradient(to right, #00c6ff, #0072ff)",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            boxShadow: "none",
            m: 0,
          }}>
          Да
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            width: "100%",
            borderRadius: 2,
            py: 1,
            borderColor: "#0072ff",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            boxShadow: "none",
          }}>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DebitedModal;
