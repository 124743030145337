// Balance.js
import React, { useState } from "react";
import { Box, Button, Typography, IconButton, Divider, Pagination } from "@mui/material";
import { styled } from "@mui/system";
import Visa from "../../assets/images/Old_Visa_Logo 1.svg";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const ITEMS_PER_PAGE = 7;

const Balance = () => {
  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const operations = [
    { date: "16.02.2024", amount: "+3000 тг", description: "Пополнение с карты" },
    { date: "16.02.2024", amount: "-3000 тг", description: "Участие в аукционе" },
    { date: "16.02.2024", amount: "+3000 тг", description: "Пополнение с карты" },
    { date: "16.02.2024", amount: "-50 тг", description: "Комиссия за ставку" },
    { date: "16.02.2024", amount: "-50 тг", description: "Комиссия за ставку" },
    { date: "16.02.2024", amount: "-50 тг", description: "Комиссия за ставку" },
    { date: "16.02.2024", amount: "-50 тг", description: "Комиссия за ставку" },
    { date: "16.02.2024", amount: "+3000 тг", description: "Пополнение с карты" },
    { date: "16.02.2024", amount: "-3000 тг", description: "Участие в аукционе" },
    { date: "16.02.2024", amount: "+3000 тг", description: "Пополнение с карты" },
    { date: "16.02.2024", amount: "-50 тг", description: "Комиссия за ставку" },
    { date: "16.02.2024", amount: "-50 тг", description: "Комиссия за ставку" },
    { date: "16.02.2024", amount: "-50 тг", description: "Комиссия за ставку" },
    { date: "16.02.2024", amount: "-50 тг", description: "Комиссия за ставку" },
  ];

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedOperations = operations.slice(startIndex, endIndex);

  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600 }}>
        Баланс
      </Typography>
      <Typography variant="h5" sx={{ fontSize: "36px", my: 4, fontWeight: 600 }}>
        0,00 тг
      </Typography>
      <Button
        variant="contained"
        sx={{
          background:
            "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
          boxShadow: "none",
          borderRadius: "10px",
          p: "6px 24px",
          fontSize: "14px",
          fontWeight: 400,
          textTransform: "none",
          mb: 4,
        }}>
        Пополнить баланс
      </Button>
      <Typography variant="h6" sx={{ fontSize: "20px", mb: 3, fontWeight: 600 }}>
        Способы оплаты
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3, p: 1 }}>
        <img src={Visa} alt="Visa" style={{ marginRight: 10 }} />
        <Typography>Карта **** 5549</Typography>
        <IconButton>
          <DeleteOutlinedIcon sx={{ color: "#89939E" }} />
        </IconButton>
      </Box>
      <Button
        variant="contained"
        sx={{
          background:
            "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
          boxShadow: "none",
          borderRadius: "10px",
          p: "6px 24px",
          fontSize: "14px",
          fontWeight: 400,
          textTransform: "none",
          mb: 4,
        }}>
        Добавить
      </Button>
      <Divider sx={{ mb: 3, borderColor: "#717171" }} />

      <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
        История операций
      </Typography>
      {paginatedOperations.map((operation, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            py: 1.5,
            backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA",
          }}>
          <Typography
            sx={{ minWidth: "120px", color: "#717171", fontSize: "14px", fontWeight: 600 }}>
            {operation.date}
          </Typography>
          <Typography
            sx={{ minWidth: "120px", color: "#717171", fontSize: "14px", fontWeight: 600 }}>
            {operation.amount}
          </Typography>
          <Typography sx={{ minWidth: "120px", color: "#717171", fontSize: "14px" }}>
            {operation.description}
          </Typography>
        </Box>
      ))}
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(operations.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default Balance;
