import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export default function Footer() {
  return (
    <Box sx={{ flexGrow: 1, p: "40px 0" }}>
      <Grid container>
        <Grid item lg={3} xs={12} sx={{ ml: 0 }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            Политика конфеденциальности
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            Условия оферты
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            Реферальная программа
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            Copyright © ТОО “Brama.kz”
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12} sx={{ mt: { lg: 0, xs: "40px" }, mb: { lg: 0, xs: "40px" } }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            Главная
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
              textAlign: "center",
            }}>
            Условия
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            Оплата и доставка
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            Контакты
          </Typography>
        </Grid>
        <Grid item lg={3} xs={12}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "black",
              fontSize: "18px",
              fontWeight: 600,
              textDecoration: "none",
            }}>
            Контакт-центр
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "#464646",
              fontSize: "18px",
              fontWeight: 600,
              textDecoration: "none",
            }}>
            8 701 543 76 54
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              p: "10px",
              display: "flex",
              color: "#464646",
              fontSize: "14px",
              fontWeight: 400,
              textDecoration: "none",
            }}>
            support@gmail.com
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
