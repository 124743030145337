import React from "react";
import { Box, Button, TextField, Typography, Grid } from "@mui/material";
import ReferralImage from "../../assets/images/Group 2287.png";

const ReferralProgram = () => {
  const referralLink = "https://brama.kz/invite/56nj5m45j3k4m";

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
    alert("Ссылка скопирована!");
  };

  return (
    <Box
      sx={{
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <Box sx={{ width: "65%" }}>
        <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600 }}>
          Реферальная программа
        </Typography>
        <Typography variant="h6" sx={{ fontSize: "20px", mt: 10, mb: 2, fontWeight: 600 }}>
          Поделитесь ссылкой или кодом
        </Typography>
        <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
          <TextField
            sx={{
              width: "50%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                borderColor: "#89939E",
                "& fieldset": {
                  borderColor: "#89939E",
                },
              },
              "& .MuiInputBase-input": {
                py: "10px",
              },
            }}
            value={referralLink}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="contained"
            onClick={handleCopy}
            sx={{
              width: "50%",
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              p: "6px 24px",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            Скопировать
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
              Шаг 1
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#000" }}>
              Вы делитесь своей <br /> ссылкой или кодом <br /> любым удобным для вас <br />
              способом
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
              Шаг 2
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#000" }}>
              Друзья по ссылке регистрируются или указывают код при регистрации
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
              Шаг 3
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#000" }}>
              После того как ваш друг внесет взнос за участие в любом аукционе, вы получите бонус на{" "}
              <br />
              кошелек.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "35%", mt: 12 }}>
        <img src={ReferralImage} alt="Referral Program" style={{ width: "100%" }} />
      </Box>
    </Box>
  );
};

export default ReferralProgram;
