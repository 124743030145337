import React from "react";
import { Breadcrumbs, Link as MuiLink, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function BreadcrumbsComponent() {
  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <MuiLink component={Link} to="/" underline="hover" color="black">
          Главная
        </MuiLink>
        <MuiLink component={Link} to="/lots" underline="hover" color="black">
          Категории
        </MuiLink>
        <MuiLink component={Link} to="/lots" underline="hover" color="black">
          Смартфоны
        </MuiLink>
        <Typography color="text.primary">Iphone 15 Pro Max</Typography>
      </Breadcrumbs>
    </Box>
  );
}
