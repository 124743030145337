import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import Lots from "./pages/Lots/Lots";
import Product from "./pages/Product/Product";
import Terms from "./pages/Terms/Terms";
import PaymentDelivery from "./pages/PaymentDelivery/PaymentDelivery";
import Contacts from "./pages/Contacts/Contacts";
import Login from "./pages/Login/Login";
import PasswordRecovery from "./pages/PasswordRecovery/PasswordRecovery";
import Register from "./pages/Register/Register";
import PersonalInfo from "./pages/Register/PersonalInfo";
import Verification from "./pages/Register/Verification";
import Checkout from "./pages/Checkout/Checkout";
import ProfilePage from "./pages/Profile/ProfilePage";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import CategoriesAdmin from "./pages/AdminPanel/CategoriesAdmin";
import LotsAdmin from "./pages/AdminPanel/LotsAdmin";
import AddNewCategory from "./pages/AdminPanel/AddNewCategory";
import AddNewLot from "./pages/AdminPanel/AddNewLot";
import OrdersAdmin from "./pages/AdminPanel/OrdersAdmin";
import OrderDetail from "./pages/AdminPanel/OrderDetail";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import "./styles/reset.scss";
import MainAdmin from "./pages/AdminPanel/MainAdmin";
import PersonalData from "./pages/Profile/PersonalData";
import MyShopping from "./pages/Profile/MyShopping";
import Favorites from "./pages/Profile/Favorites";
import Balance from "./pages/Profile/Balance";
import MyAuctions from "./pages/Profile/MyAuctions";
import ReferralProgram from "./pages/Profile/ReferralProgram";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/lots" element={<Lots />} />
        <Route path="/lots/:lot" element={<Lots />} />
        <Route path="/lots/smartphones/iphone-15-pro-max" element={<Product />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/payment-and-delivery" element={<PaymentDelivery />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/personal-info" element={<PersonalInfo />} />
        <Route path="/register/verification" element={<Verification />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/profile" element={<ProfilePage />}>
          <Route path="личные-данные" element={<PersonalData />} />
          <Route path="мои-покупки" element={<MyShopping />} />
          <Route path="избранное" element={<Favorites />} />
          <Route path="баланс" element={<Balance />} />
          <Route path="мои-аукционы" element={<MyAuctions />} />
          <Route path="реферальная-программа" element={<ReferralProgram />} />
        </Route>
        <Route path="/admin" element={<AdminPanel />}>
          <Route path="main" element={<MainAdmin />} />
          <Route path="categories" element={<CategoriesAdmin />} />
          <Route path="lots" element={<LotsAdmin />} />
          <Route path="add-category" element={<AddNewCategory />} />
          <Route path="add-lot" element={<AddNewLot />} />
          <Route path="orders" element={<OrdersAdmin />} />
          <Route path="order-detail" element={<OrderDetail />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
