import React from "react";
import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { categories, lotStatus } from "../../DB/Sidebar";

const Sidebar = () => (
  <Grid item xs={12} md={3} sx={{ padding: 2 }}>
    <Typography variant="h6" sx={{ fontSize: "28px", fontWeight: 600, mb: 2 }}>
      Категории
    </Typography>
    <Box>
      {categories.map((category) => (
        <Typography key={category} sx={{ fontSize: "18px", fontWeight: 400, py: 1 }}>
          <Link
            to={"/lots/smartphones/iphone-15-pro-max"}
            style={{ textDecoration: "none", color: "inherit" }}>
            {category}
          </Link>
        </Typography>
      ))}
    </Box>
    <Typography variant="h6" sx={{ mt: 2 }}>
      Показывать лоты
    </Typography>
    <Box>
      {lotStatus.map((status) => (
        <FormControlLabel
          key={status}
          control={<Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: "24px" } }} />}
          label={status}
          sx={{ display: "block", fontSize: "16px", fontWeight: 400, my: 1 }}
        />
      ))}
    </Box>
  </Grid>
);

export default Sidebar;
