import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import FundsModal from "../FundsModal/FundsModal";

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date("2024-07-29") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const renderBox = (value, label) => (
    <Box
      mx={1}
      textAlign="center"
      sx={{
        pt: 1,
        background: "#343650",
        color: "#fff",
        borderRadius: 1,
        width: "64px",
        height: "64px",
        display: "flex",
        flexDirection: "column",
      }}>
      <Box
        sx={{
          fontSize: "36px",
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {String(value).padStart(2, "0")}
      </Box>
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: 600,
          width: "100%",
          textAlign: "center",
        }}>
        {label}
      </Typography>
    </Box>
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {renderBox(timeLeft.hours, "ЧАСОВ")}
      {renderBox(timeLeft.minutes, "МИНУТ")}
      {renderBox(timeLeft.seconds, "СЕКУНД")}
    </Box>
  );
};

export default function Rate() {
  const [isFundsModalOpen, setFundsModalOpen] = useState(false);

  const handleOpenFundsModal = () => {
    setFundsModalOpen(true);
  };

  const handleCloseFundsModal = () => {
    setFundsModalOpen(false);
  };

  return (
    <Grid item xs={12} lg={5}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600 }}>
        Iphone 15 pro max
      </Typography>
      <Box display={"flex"} alignItems={"center"} my={2}>
        <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: 400, mr: 1 }}>
          Завершится через:
        </Typography>
        <CountdownTimer />
      </Box>

      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: 3,
          background: "rgba(246, 53, 53, 0.50)",
          width: "100%",
          textTransform: "none",
          my: 2,
        }}>
        <Typography
          variant="body2"
          sx={{
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
          }}
          align="center">
          Текущая ставка:
        </Typography>
        <Typography
          sx={{
            ml: 0.5,
            color: "black",
            fontSize: "14px",
            fontWeight: 700,
          }}
          align="center">
          75000 тг
        </Typography>
      </Button>
      <Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid var(--primary-blue-light-status, #E3EEFF)", py: 1 }}>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400, mr: "auto" }}>
            Номер лота
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400 }}>
            Ab3545689
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid var(--primary-blue-light-status, #E3EEFF)", py: 1 }}>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400, mr: "auto" }}>
            Стартовая цена:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400 }}>
            50 000 тг
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ borderBottom: "1px солид var(--primary-blue-light-status, #E3EEFF)", py: 1 }}>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400, mr: "auto" }}>
            Взнос за участие
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400 }}>
            3 000 тг
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid var(--primary-blue-light-status, #E3EEFF)", py: 1 }}>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400, mr: "auto" }}>
            Минимальное повышение
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 400 }}>
            500 тг
          </Typography>
        </Box>
      </Box>
      <Button
        onClick={handleOpenFundsModal}
        sx={{
          mt: 3,
          color: "#fff",
          borderRadius: 3,
          fontSize: "16px",
          fontWeight: 400,
          width: "100%",
          textTransform: "none",
          py: 1,
          background:
            "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
        }}>
        Участвовать
      </Button>
      <FundsModal open={isFundsModalOpen} onClose={handleCloseFundsModal} />
    </Grid>
  );
}
