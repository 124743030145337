import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  InputAdornment,
  IconButton,
  Container,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container className="container">
      <Header />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          maxWidth: "460px",
          margin: "0 auto",
          my: 5,
        }}>
        <Typography variant="h4" sx={{ fontSize: "36px", mb: 4, fontWeight: 600 }}>
          Вход
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            Электронная почта или логин
          </Typography>
          <TextField
            label="Электронная почта или логин"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Пароль</Typography>
          <TextField
            label="Пароль"
            variant="outlined"
            fullWidth
            type={showPassword ? "text" : "password"}
            sx={{
              mb: 1,
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
              component={RouterLink}
              to="/password-recovery"
              href="#"
              sx={{
                fontSize: "12px",
                color: "#0072ff",
                textDecoration: "none",
              }}>
              Напомнить пароль?
            </Link>
          </Box>
        </Box>

        <Button
          variant="contained"
          fullWidth
          sx={{
            my: 3,
            background:
              "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
            boxShadow: "none",
            borderRadius: "10px",
            py: "12px",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
          }}>
          Войти
        </Button>
        <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: 400 }}>
          Нет аккаунта?
          <Link
            component={RouterLink}
            to="/register"
            href="#"
            sx={{
              ml: 3,
              fontSize: "14px",
              fontWeight: 400,
              color: "#0671E0",
              textDecoration: "none",
            }}>
            Зарегистрируйтесь
          </Link>
        </Typography>
      </Box>
      <Footer />
    </Container>
  );
};

export default Login;
