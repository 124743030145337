// OrdersAdmin.js
import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  TextField,
  Paper,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import OrderDetail from "./OrderDetail";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const textFieldStyles = {
  pr: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: 1.5,
    fontSize: "12px",
  },
  width: "100%",
};

const OrdersAdmin = () => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const handleRowClick = (order) => {
    setSelectedOrder(order);
  };

  const handleBack = () => {
    setSelectedOrder(null);
  };

  const orders = [
    {
      lotNumber: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      cost: "34 500 тг",
      customer: "Иванов Константин",
      status: "Новый",
    },
    {
      lotNumber: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      cost: "34 500 тг",
      customer: "Иванов Константин",
      status: "Не подтвержден",
    },
    {
      lotNumber: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      cost: "34 500 тг",
      customer: "Иванов Константин",
      status: "Подтвержден",
    },
    {
      lotNumber: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      cost: "34 500 тг",
      customer: "Иванов Константин",
      status: "Отправлен",
    },
    {
      lotNumber: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      cost: "34 500 тг",
      customer: "Иванов Константин",
      status: "Завершен",
    },
    // Add more orders as needed
  ];

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedOrders = orders.slice(startIndex, endIndex);

  if (selectedOrder) {
    return <OrderDetail order={selectedOrder} onBack={handleBack} />;
  }

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 2 }}>
        Заказы
      </Typography>
      <Table sx={{ minWidth: 650, my: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 100,
              }}>
              № Лот
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Товар
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Дата
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Стоимость
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Покупатель
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                px: 1,
                py: 1.5,
                borderBottom: "1px solid #ABBED1",
                width: 120,
              }}>
              Статус
              <TextField
                placeholder="Поиск"
                variant="outlined"
                size="small"
                sx={{ ...textFieldStyles, mt: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedOrders.map((order, index) => (
            <TableRow
              key={index}
              sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}
              onClick={() => handleRowClick(order)}
              style={{ cursor: "pointer" }}>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.lotNumber}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.product}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.date}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.cost}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>{order.customer}</TableCell>
              <TableCell sx={{ borderBottom: "none", py: 1.5, px: 1 }}>
                <Box
                  sx={{
                    backgroundColor:
                      order.status === "Завершен"
                        ? "transparent"
                        : order.status === "В процессе"
                        ? "#FEEADD"
                        : order.status === "Отправлен"
                        ? "#00CD52"
                        : order.status === "Подтвержден"
                        ? "#D9F8E5"
                        : order.status === "Не подтвержден"
                        ? "#FEEADD"
                        : "#E3EEFF",
                    borderRadius: "8px",
                    p: "5px 10px",
                    textAlign: "center",
                    width: "fit-content",
                  }}>
                  {order.status}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Количество элементов:</Typography>
          <FormControl variant="outlined" sx={{ ml: 2, minWidth: 60 }}>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              displayEmpty
              inputProps={{ "aria-label": "Items per page" }}
              sx={{ height: "35px", borderRadius: 2 }}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <StyledPagination
          count={Math.ceil(orders.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Paper>
  );
};

export default OrdersAdmin;
