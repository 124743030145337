import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  ListItemIcon,
  IconButton,
  Grid,
} from "@mui/material";
import { Gavel, ExitToApp, MenuRounded as MenuRoundedIcon } from "@mui/icons-material";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

const menuIcons = {
  "Личные данные": <PersonOutlinedIcon />,
  Баланс: <AccountBalanceWalletOutlinedIcon />,
  "Реферальная программа": <CampaignOutlinedIcon />,
  "Мои аукционы": <Gavel />,
  "Мои покупки": <ShoppingCartOutlinedIcon />,
  Избранное: <FavoriteBorderOutlinedIcon />,
  Выход: <ExitToApp />,
};

const menuRoutes = {
  "Личные данные": "личные-данные",
  Баланс: "баланс",
  "Реферальная программа": "реферальная-программа",
  "Мои аукционы": "мои-аукционы",
  "Мои покупки": "мои-покупки",
  Избранное: "избранное",
};

const ProfilePage = () => {
  const [menuItems] = useState([
    "Личные данные",
    "Баланс",
    "Реферальная программа",
    "Мои аукционы",
    "Мои покупки",
    "Избранное",
    "Выход",
  ]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenuItemClick = (item) => {
    setMobileMenuOpen(false);
    if (item === "Выход") {
      // Handle logout logic
    } else {
      navigate(`/profile/${menuRoutes[item]}`);
    }
  };

  const handleMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <Container className="container">
      <Header />
      <Paper
        component="main"
        sx={{
          px: 3,
          py: 3,
          my: 5,
          borderRadius: "20px",
          boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} sx={{ display: { xs: "block", md: "none" } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}>
              <Typography fontSize={"18px"} variant="h6">
                Личный кабинет
              </Typography>
              <IconButton
                onClick={handleMenuToggle}
                sx={{
                  mr: 2,
                  ml: 0,
                  display: { md: "none" },
                  color: "#3083FF",
                  p: 0,
                }}>
                <MenuRoundedIcon sx={{ fontSize: "48px" }} />
              </IconButton>
            </Box>
            {mobileMenuOpen ? (
              <List>
                {menuItems.map((item) => (
                  <ListItem
                    button
                    key={item}
                    onClick={() => handleMenuItemClick(item)}
                    sx={{
                      backgroundColor: window.location.pathname.includes(menuRoutes[item])
                        ? "rgba(47, 128, 237, 0.10)"
                        : "transparent",
                      "&:hover": {
                        backgroundColor: "#E6F7FF",
                      },
                      p: 1,
                    }}>
                    <ListItemIcon
                      sx={{
                        color: window.location.pathname.includes(menuRoutes[item])
                          ? "#3083FF"
                          : "#89939E",
                        minWidth: 32,
                      }}>
                      {menuIcons[item]}
                    </ListItemIcon>
                    <ListItemText
                      primary={item}
                      primaryTypographyProps={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: window.location.pathname.includes(menuRoutes[item])
                          ? "#3083FF"
                          : "#000",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <List>
                <ListItem
                  sx={{
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    p: 1,
                  }}>
                  <ListItemIcon sx={{ color: "#89939E", minWidth: 32 }}>
                    {
                      menuIcons[
                        menuItems.find((item) =>
                          window.location.pathname.includes(menuRoutes[item]),
                        )
                      ]
                    }
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      menuItems.find((item) =>
                        window.location.pathname.includes(menuRoutes[item]),
                      ) || "Личные данные"
                    }
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "#000",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  />
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item xs={12} md={2} sx={{ display: { xs: "none", md: "block" } }}>
            <Typography variant="h6" sx={{ fontSize: "18px", mb: 2, fontWeight: 400 }}>
              Личный кабинет
            </Typography>
            <List>
              {menuItems.map((item) => (
                <ListItem
                  button
                  key={item}
                  component={NavLink}
                  to={`/profile/${menuRoutes[item]}`}
                  sx={{
                    "&.active": {
                      backgroundColor: "rgba(47, 128, 237, 0.10)",
                      color: "#2F80ED",
                    },
                    "& .MuiListItemIcon-root": {
                      color: "#89939E",
                    },
                    "&.active .MuiListItemIcon-root": {
                      color: "#2F80ED",
                    },
                    "& .MuiListItemText-root .MuiTypography-root": {
                      color: "#000",
                    },
                    "&.active .MuiListItemText-root .MuiTypography-root": {
                      color: "#2F80ED",
                    },
                    "&:hover": {
                      backgroundColor: "#E6F7FF",
                    },
                    p: 1,
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 32,
                    }}>
                    {menuIcons[item]}
                  </ListItemIcon>
                  <ListItemText
                    primary={item}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: 400,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={10}>
            <Outlet />
          </Grid>
        </Grid>
      </Paper>
      <Footer />
    </Container>
  );
};

export default ProfilePage;
