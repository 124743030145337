import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Terms = () => {
  return (
    <Container className="container">
      <Header />
      <Box
        component="main"
        sx={{
          my: 6,
        }}>
        <Typography variant="h1" sx={{ fontSize: "36px", fontWeight: 600, mb: 5 }}>
          Условия
        </Typography>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            1. Регистрация участников.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            1.1. Для участия в аукционе необходимо зарегистрироваться на сайте аукционного дома.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            1.2. При регистрации участник обязуется предоставить достоверные данные о себе.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            1.3. Регистрация является бесплатной.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            2. Правила участия.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            2.1. Участник обязуется соблюдать правила аукциона, установленные аукционным домом.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            2.2. Участник несет ответственность за любые действия, совершенные от его имени на
            аукционе.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            3. Ставки.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            3.1. Ставка на аукционе считается действительной после ее подтверждения аукционным
            домом.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            3.2. Участник не имеет права отменить свою ставку после подтверждения.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            4. Оплата и доставка.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            4.1. Победитель аукциона обязуется оплатить лот в течение указанного срока.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            4.2. Аукционный дом осуществляет доставку лотов победителям на их адреса.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            5. Гарантии и возврат.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            5.1. Аукционный дом гарантирует подлинность и качество лотов, продаваемых на аукционе.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            5.2. Участник имеет право вернуть лот и получить возврат средств в случае выявления
            фактов подделки или несоответствия заявленным характеристикам.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            6. Ответственность сторон.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            6.1. Аукционный дом не несет ответственности за действия участников, нарушающих правила
            аукциона.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            6.2. Участник несет ответственность за предоставление недостоверной информации при
            регистрации.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            7. Изменение условий.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            7.1. Аукционный дом оставляет за собой право вносить изменения в условия аукциона.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            7.2. Участникам будет уведомлено о любых изменениях в условиях.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            8. Прочее.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            8.1. Все споры и разногласия, возникающие между участниками и аукционным домом, решаются
            путем переговоров.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            8.2. Настоящие условия являются обязательными для всех участников аукциона.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export default Terms;
