import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Typography, Box, Button, IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./Banner.scss";
import SlidesData from "../../DB/SlidesData";

const Carousel = () => {
  const sliderRef = useRef();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div style={{ position: "relative" }}>
      <Slider ref={sliderRef} {...settings}>
        {SlidesData.map((slide, index) => (
          <div key={index}>
            <Grid
              container
              className="banner"
              sx={{
                mt: { lg: 5, xs: 2 },
                height: { lg: "418px", xs: "34vw" },
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                position: "relative",
              }}>
              <Grid item xs={5}>
                <Box sx={{ display: "flex", alignItems: "center", mb: { lg: 2, xs: 1 } }}>
                  <Typography
                    className="title"
                    variant="h3"
                    component="div"
                    sx={{
                      ml: "25%",
                      color: "white",
                      fontSize: { lg: "42px", xs: "3.2vw" },
                      fontWeight: 700,
                      wordWrap: "break-word",
                    }}>
                    {slide.title}
                  </Typography>
                </Box>
                <Box sx={{ ml: "25%", display: "flex", alignItems: "center", color: "white" }}>
                  <Typography
                    sx={{ fontWeight: 400, mr: "10px", fontSize: { lg: "20px", xs: "1.6vw" } }}
                    variant="subtitle1">
                    Начало:
                  </Typography>
                  <Typography sx={{ fontWeight: 700, fontSize: { lg: "20px", xs: "1.6vw" } }}>
                    {slide.startDate}
                  </Typography>
                </Box>
                <Box sx={{ ml: "25%", display: "flex", alignItems: "center", color: "white" }}>
                  <Typography
                    sx={{ fontWeight: 400, mr: "10px", fontSize: { lg: "20px", xs: "1.6vw" } }}
                    variant="subtitle1">
                    Стартовая цена:
                  </Typography>
                  <Typography sx={{ fontWeight: 700, fontSize: { lg: "20px", xs: "1.6vw" } }}>
                    {slide.startPrice}
                  </Typography>
                </Box>
                <Button
                  sx={{
                    mt: { lg: 3, xs: 2 },
                    ml: "25%",
                    backgroundColor: "white",
                    fontWeight: 400,
                    borderRadius: 2,
                    fontSize: { lg: "16px", xs: "1.1vw" },
                  }}
                  variant="solid">
                  Принять участие
                </Button>
              </Grid>
            </Grid>
          </div>
        ))}
      </Slider>
      <IconButton
        className="slick-prev"
        sx={{
          position: "absolute",
          left: "-20px",
          top: "55%",
          transform: "translateY(-50%)",
          color: "black",
          backgroundColor: "white",
          boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          width: 40,
          height: 40,
          "&:hover": {
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          },
          "&:focus": {
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          },
        }}
        onClick={prevSlide}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton
        className="slick-next"
        sx={{
          position: "absolute",
          right: "-20px",
          top: "55%",
          transform: "translateY(-50%)",
          color: "black",
          backgroundColor: "white",
          boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          width: 40,
          height: 40,
          "&:hover": {
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          },
          "&:focus": {
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
          },
        }}
        aria-label="next"
        onClick={nextSlide}>
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

export default Carousel;
