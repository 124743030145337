import React, { useState } from "react";
import { Box, Grid, CardMedia } from "@mui/material";

export default function ProductSlider({ images }) {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <Grid item xs={12} lg={4}>
      <CardMedia
        component="img"
        image={selectedImage.src}
        alt={selectedImage.alt}
        sx={{
          width: "100%",
          height: "auto",
          borderRadius: 2,
          mb: 2,
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {images.map((image, index) => (
          <Box key={index} sx={{ mx: 1 }}>
            <CardMedia
              component="img"
              image={image.src}
              alt={image.alt}
              onClick={() => handleImageClick(image)}
              sx={{
                cursor: "pointer",
                width: "100%",
                height: "auto",
                maxWidth: "100px",
                borderRadius: 2,
                boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
                "&:hover": {
                  border: "1px solid #3083FF",
                },
              }}
            />
          </Box>
        ))}
      </Box>
    </Grid>
  );
}
