import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Drawer from "@mui/material/Drawer";
import { Menu, MenuItem } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function DrawerHeader({
  drawerOpen,
  toggleDrawer,
  handleClick,
  handleClose,
  anchorEl,
  open,
}) {
  return (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
      <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
        <IconButton
          className="logo"
          sx={{
            py: 3,
            px: 2,
            display: "block",
            color: "#3083FF",
          }}>
          <CloseRoundedIcon sx={{ fontSize: "48px" }} />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#"
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "block",
          }}>
          Главная
        </Typography>
        <Typography
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          disableElevation
          variant="h6"
          noWrap
          component="a"
          href="#"
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "flex",
            cursor: "pointer",
          }}>
          Категории
          <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
            <ArrowDropDownIcon sx={{ pb: "3px" }} />
          </Box>
        </Typography>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            sx: {
              mt: 1.5,
              ml: -1.5,
              boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
              borderRadius: 2,
              minWidth: 165,
            },
          }}>
          <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
            Iphones
          </MenuItem>
          <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
            Смартфоны
          </MenuItem>
          <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
            Телевизоры
          </MenuItem>
          <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
            Аудиотехника
          </MenuItem>
          <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
            Фототехника
          </MenuItem>
        </Menu>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#"
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "block",
          }}>
          Условия
        </Typography>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#"
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "block",
          }}>
          Оплата и доставка
        </Typography>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#"
          sx={{
            py: 2,
            px: 3,
            color: "black",
            fontSize: "14px",
            fontWeight: 400,
            textDecoration: "none",
            display: "block",
          }}>
          Контакты
        </Typography>
      </div>
    </Drawer>
  );
}
