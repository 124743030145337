import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const CustomStepIcon = (props) => {
  const { active, icon } = props;

  return (
    <Box
      sx={{
        position: "relative",
        width: 44,
        height: 44,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: active ? "linear-gradient(180deg, #3083FF 0%, #73ABFF 100%)" : "#f0f2f5",
        borderColor: active ? "none" : "#ABBED1",
        borderWidth: active ? "none" : "2px",
        borderStyle: "solid",
      }}>
      <Typography
        sx={{
          color: active ? "#fff" : "#ABBED1",
          fontSize: "20px",
          fontWeight: 600,
          position: "absolute",
        }}>
        {icon}
      </Typography>
    </Box>
  );
};

const PersonalInfo = () => {
  const navigate = useNavigate();

  const handleNextStep = () => {
    navigate("/register/verification");
  };

  return (
    <Container className="container">
      <Header />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          maxWidth: "460px",
          margin: "0 auto",
          my: 5,
        }}>
        <Stepper
          activeStep={1}
          alternativeLabel
          sx={{
            width: "80%",
            "& .MuiStep-root": {
              p: 0,
            },
            "& .MuiStepLabel-root .MuiStepLabel-label": {
              display: "none",
            },
            "& .MuiStepConnector-root": {
              top: "21px",
              left: "calc(-50% + 22px)",
              right: "calc(50% + 20px)",
            },
            "& .MuiStepIcon-root": {
              width: "44px",
              height: "44px",
              color: "#f0f2f5",
              borderRadius: "50%",
              border: "2px solid #ABBED1",
              background: "none",
              "&.Mui-active": {
                color: "transparent",
                background:
                  "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                border: "none",
              },
              "&.Mui-active .MuiStepIcon-text": {
                fill: "#fff",
              },
            },
            "& .MuiStepConnector-line": {
              borderColor: "#ABBED1",
              borderTopWidth: "2px",
              top: "50%",
            },
            "& .MuiStepIcon-text": {
              fill: "#ABBED1",
              fontSize: "12px",
              fontWeight: 600,
            },
          }}>
          {[1, 2, 3].map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Typography variant="h4" sx={{ fontSize: "36px", my: 4, fontWeight: 600 }}>
          Регистрация
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "14px", mb: 4, fontWeight: 400 }}>
          Заполните персональные данные
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Фамилия</Typography>
          <TextField
            label="Фамилия"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Имя</Typography>
          <TextField
            label="Имя"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Дата рождения</Typography>
          <TextField
            label="Дата рождения"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Страна</Typography>
          <TextField
            label="Страна"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Город</Typography>
          <TextField
            label="Город"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Улица</Typography>
          <TextField
            label="Улица"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Номер дома</Typography>
          <TextField
            label="Номер дома"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Квартира/офис</Typography>
          <TextField
            label="Квартира/офис"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="outlined"
              onClick={handleNextStep}
              sx={{
                width: "45%",
                borderColor: "#3083FF",
                color: "#3083FF",
                py: "14px",
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "none",
                borderRadius: "10px",
              }}>
              Пропустить
            </Button>
            <Button
              variant="contained"
              onClick={handleNextStep}
              sx={{
                width: "45%",
                background:
                  "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                boxShadow: "none",
                borderRadius: "10px",
                py: "14px",
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "none",
              }}>
              Продолжить
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export default PersonalInfo;
