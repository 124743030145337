import React, { useRef, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import CategoriesData from "../../DB/CategoriesData";

export default function Categories() {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.clientX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{
        height: "200px",
        mt: { lg: 3, xs: 1 },
        pt: 1,
        pl: 1,
        display: "flex",
        overflowX: "auto",
        flexWrap: "nowrap",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}>
      {CategoriesData.map((item, index) => (
        <Grid key={index} item sx={{ mr: 1 }}>
          <Paper
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
              "&:hover": {
                border: "1px solid #3083FF",
              },
            }}>
            <Box>
              <Typography sx={{ fontWeight: 400, textAlign: "center" }} variant="subtitle1">
                <img src={item.img} alt={item.title} />
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  textAlign: "center",
                  fontSize: "10px",
                  cursor: "default",
                  mb: -1,
                }}
                variant="caption"
                display="block"
                gutterBottom>
                {item.title}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
