import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  CardMedia,
  Box,
  styled,
  IconButton,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import Cards from "../../DB/Cards";

export default function NearestItemsSlider() {
  const [favorite, setFavorite] = useState(Array(Cards.length).fill(false));
  const sliderRef = useRef();
  const navigate = useNavigate();

  const handleFavoriteClick = (index) => {
    const newFavorite = [...favorite];
    newFavorite[index] = !newFavorite[index];
    setFavorite(newFavorite);
  };

  const StyledIconButton = styled(IconButton)({
    "&::before, &::after": {
      display: "none",
    },
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const navigateToLots = () => {
    navigate("/lots");
  };

  const participateInAuction = () => {
    navigate("/lots/smartphones/iphone-15-pro-max");
  };

  return (
    <Box sx={{ my: 7 }}>
      <Grid sx={{ mb: 4 }} item container justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontSize: "36px", fontWeight: 500 }} variant="h4">
          Успей выкупить
        </Typography>
        <Button
          sx={{
            padding: "14px 32px",
            color: "#3083FF",
            border: "1px solid #3083FF",
            borderRadius: 2,
          }}
          variant="outlined"
          onClick={navigateToLots}>
          Все лоты
        </Button>
      </Grid>
      <Box style={{ position: "relative" }}>
        <Slider ref={sliderRef} {...settings}>
          {Cards.map((item, index) => (
            <Box key={index}>
              <Grid item xs={12} lg={3} sm={6} px={0.75}>
                <Card
                  sx={{
                    borderRadius: 6,
                    boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
                    mb: 3,
                  }}>
                  <Box
                    sx={{
                      position: "absolute",
                      m: 2,
                    }}>
                    <StyledIconButton
                      sx={{ color: "white", width: 32, height: 32 }}
                      onClick={() => handleFavoriteClick(index)}
                      aria-label="favorite">
                      {favorite[index] ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
                    </StyledIconButton>
                  </Box>
                  <CardMedia
                    sx={{ height: 170, borderRadius: 6 }}
                    image={item.image}
                    title={item.title}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ p: "11px 0" }}>
                      <Typography
                        sx={{ fontSize: "20px", fontWeight: 600, pt: 1 }}
                        variant="subtitle1"
                        align="center">
                        {item.title}
                      </Typography>
                      <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
                        <Box
                          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 400,
                              lineHeight: "16px",
                            }}
                            variant="caption"
                            align="center">
                            Стартовая цена:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                            }}
                            variant="caption"
                            align="center">
                            {item.price}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 400,
                              lineHeight: "16px",
                            }}
                            variant="caption"
                            align="center">
                            Начнется через
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                            }}
                            variant="caption"
                            align="center">
                            {item.time}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Button
                      fullWidth
                      sx={{
                        background:
                          "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                        height: "32px",
                        boxShadow: "0px 4px 35px 0px rgba(2, 67, 148, 0.08)",
                        borderRadius: 1.5,
                        mt: 1,
                      }}
                      variant="contained"
                      onClick={participateInAuction}>
                      Принять участие
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Box>
          ))}
        </Slider>
        <IconButton
          className="slick-prev"
          sx={{
            position: "absolute",
            left: "-20px",
            top: "45%",
            transform: "translateY(-50%)",
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            width: 40,
            height: 40,
            "&:hover": {
              color: "black",
              backgroundColor: "white",
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            },
            "&:focus": {
              color: "black",
              backgroundColor: "white", // Убираем эффект focus
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)", // Убираем эффект focus
            },
          }}
          onClick={prevSlide}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <IconButton
          className="slick-next"
          sx={{
            position: "absolute",
            right: "-20px",
            top: "45%",
            transform: "translateY(-50%)",
            color: "black",
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            width: 40,
            height: 40,
            "&:hover": {
              color: "black",
              backgroundColor: "white",
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)",
            },
            "&:focus": {
              color: "black",
              backgroundColor: "white", // Убираем эффект focus
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.07)", // Убираем эффект focus
            },
          }}
          aria-label="next"
          onClick={nextSlide}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
