import React from "react";
import { Grid, Typography, Tooltip, Box } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CustomTooltip = ({ title, children }) => (
  <Tooltip
    title={
      <Box
        sx={{
          p: 2,
          bgcolor: "#fff",
          color: "#000",
          borderRadius: 2,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          width: "430px",
          maxWidth: "none",
        }}>
        <Typography variant="body2" sx={{ textAlign: "center", fontSize: "12px" }}>
          {title}
        </Typography>
      </Box>
    }
    placement="bottom"
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: "transparent",
          "& .MuiTooltip-arrow": {
            color: "#fff",
          },
          maxWidth: "none",
        },
      },
      arrow: {
        sx: {
          color: "#fff",
        },
      },
    }}>
    {children}
  </Tooltip>
);

export default function Info() {
  return (
    <Grid
      container
      sx={{
        justifyContent: "space-between",
        background: "linear-gradient(90deg, #EA610B 0%, #FF9A5A 100%)",
        borderRadius: 5,
        color: "#fff",
        alignItems: "center",
        width: "100%",
        mt: 5,
        mb: 3,
      }}>
      <Grid item xs={11}>
        <Grid container alignItems="center" justifyContent="center">
          <img
            src="https://s3-alpha-sig.figma.com/img/5750/8e9b/46e52f21a59f68eb888d58e162eedf6f?Expires=1719187200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EJfYrBGLYPwc5MttV06RvXkrrZIX9V6Xv8VNutbRj3JFGMwaU4Azq8sYhQVcVnFpJFJFB-zYWcrhOT2T3rpwDvsgpWuZidbsX62cFP6w-hJOcIkr7pE8cCKTLmL6S6X8pF2ViQzqdOI9EwRRtJxDOzMkZ1gqpBldgAkrwEoO6-Ei5WFO5q3h19v8MORQvn-jmKN3LbTJu-~3umhb~vmsACVTODjwhj3eKQ9-e6BZADT0cOia8S5Rw0wvKK~pt4gf4gRCWiD7BXOgCqsp4JMGNBnJGgjdHnWpshHjY7Adx-njdONV8eb8lxoj~2I3ZtPCUjyCoZ1gkkzu8y2WQmeatg__"
            alt="gif"
            style={{ width: "70px" }}
          />
          <Typography variant="body1" sx={{ ml: 2 }}>
            10 самых активных и 10 пользователей с наивысшими ставками получат гарантированные призы
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
        <CustomTooltip title="Ставьте ставки и участвуйте в розыгрыше гарантированных призов 10 активных пользователей, с наибольшим количеством попыток, а так же 10 пользователей, поставивших максимальные суммы, получат денежные призы.">
          <InfoOutlinedIcon sx={{ cursor: "pointer", color: "#fff" }} />
        </CustomTooltip>
      </Grid>
    </Grid>
  );
}
