import React from "react";
import { Box, IconButton, Button } from "@mui/material";
import { Search as SearchIcon, PersonOutlined as PersonOutlinedIcon } from "@mui/icons-material";

const Unauthorized = ({ onUserIconClick }) => (
  <Box sx={{ ml: "auto" }}>
    <IconButton sx={{ color: "#3083FF", border: "1px solid #3083FF", borderRadius: 2, mr: "12px" }}>
      <SearchIcon sx={{ fontSize: "16px" }} />
    </IconButton>
    <IconButton
      onClick={onUserIconClick}
      sx={{ color: "#3083FF", border: "1px solid #3083FF", borderRadius: 2, mr: "12px" }}>
      <PersonOutlinedIcon sx={{ fontSize: "16px" }} />
    </IconButton>
    <Button
      sx={{
        padding: "4px 22px",
        color: "#3083FF",
        fontSize: "14px",
        fontWeight: 400,
        border: "1px solid #3083FF",
        borderRadius: 2,
      }}
      variant="outlined">
      RU
    </Button>
  </Box>
);

export default Unauthorized;
