import React from "react";
import { Box, Typography } from "@mui/material";

export default function Description() {
  return (
    <Box sx={{ my: 12 }}>
      <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: "bold" }}>
        Описание
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "16px" }}>
        Apple iPhone 15 Pro Max - сверхпрочный флагман, изготовленный из титана. За бескомпромиссную
        производительность смартфона отвечает новейший чип Apple A17 Pro. Устройство получило
        передовую систему камер, продуманную эргономику, обновленный дизайн и новую программируемую
        кнопку.
      </Typography>
      <Typography variant="body1" component="div" sx={{ fontSize: "16px" }}>
        <ul style={{ paddingLeft: "20px", color: "black", listStyleType: "disc" }}>
          <li>
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              <strong>Титановый корпус</strong>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", marginLeft: -2 }}>
              Титановый корпус стал одновременно и прочнее, и легче корпуса из стали. Внутренний
              структурный каркас iPhone из 100% переработанного алюминия. Вес флагмана составляет
              221 г.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              <strong>Процессор</strong>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", marginLeft: -2 }}>
              Новый процессор A17 Pro получил на 10% увеличенную производительность по сравнению с
              предыдущим поколением. Его мощности с запасом хватает для выполнения самых сложных
              задач.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              <strong>Дисплей</strong>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", marginLeft: -2 }}>
              Дисплей диагональю 6.7” точно передает цвета и обеспечивает изображение с высочайшим
              уровнем детализации. Максимальная частота обновления экрана составляет 120 Гц,
              благодаря чему все переходы выглядят плавно и без рывков. Стекло с покрытием Ceramic
              Shield надёжно защищает дисплей от царапин и сколов.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              <strong>Основная камера</strong>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", marginLeft: -2 }}>
              Основная камера с разрешением 48 Мп, увеличенным светочувствительным сенсором и
              апертурой f/1.78 способна делать детализированные фотографии как днём, так и ночью.
              Сверхширокоугольный модуль с разрешением 12 Мп и апертурой f/2.2 получил угол обзора
              120 градусов, что позволяет поместить в кадре все нужные детали. Телеобъектив с
              разрешением 12 Мп и апертурой f/2.8 и пятикратным оптическим зумом позволяет
              максимально приблизить объекты и запечатлеть это без потери качества. Для записи
              плавных видео в движении смартфон оснащён продвинутой оптической стабилизацией,
              которая работает сразу в нескольких направлениях.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              <strong>Фронтальная камера</strong>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", marginLeft: -2 }}>
              Фронтальная камера получила разрешение 12 Мп. Благодаря поддержке технологии TrueDepth
              цвета на снимке получаются глубокими и насыщенными.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              <strong>Передача данных до 20 раз быстрее</strong>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", marginLeft: -2 }}>
              Через USB Type-C устройство может передавать информацию до 10 Гбит/с.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "16px" }}>
              <strong>Большое время автономной работы</strong>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "16px", marginLeft: -2 }}>
              Аккумулятор смартфона обеспечивает до 29 часов работы в режиме просмотра видео. За 30
              минут зарядки смартфон восполняет 50% заряда.
            </Typography>
          </li>
        </ul>
      </Typography>
    </Box>
  );
}
