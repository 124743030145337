import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  IconButton,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Cards from "../../DB/Cards";
import Image from "../../assets/images/card_img_8.png";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const ITEMS_PER_PAGE = 12;

const Favorites = () => {
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDelete = (index) => {};

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedCards = Cards.slice(startIndex, endIndex);

  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 3 }}>
        Избранное
      </Typography>
      <Grid container spacing={2}>
        {paginatedCards.map((item, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <Card
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                p: 2,
                boxShadow: "none",
                border: "1px solid #ABBED1",
                borderRadius: "0px",
              }}>
              <CardMedia
                component="img"
                sx={{ width: { xs: "100%", sm: 100 }, height: "100%" }}
                image={Image}
                alt={item.title}
              />
              <CardContent sx={{ flex: "1 0 auto", py: { xs: 1, sm: 0 } }}>
                <Typography fontSize={"20px"} fontWeight={600} component="div" variant="h5">
                  {item.title}
                </Typography>
                <Typography variant="subtitle1" fontSize={"14px"} my={1} component="div">
                  Дата начала: 05.03.2024
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    background:
                      "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                    boxShadow: "none",
                    borderRadius: "10px",
                    p: "6px 24px",
                    fontSize: "14px",
                    fontWeight: 400,
                    textTransform: "none",
                  }}>
                  Принять участие
                </Button>
              </CardContent>
              <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: { xs: 0, sm: 8 } }}>
                <IconButton
                  sx={{ pt: 0, pb: { xs: 0, sm: 3 } }}
                  aria-label="delete"
                  onClick={() => handleDelete(index)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(Cards.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default Favorites;
