import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import DebitedModal from "../DebitedModal/DebitedModal";

function AcquiringModal({ open, onClose }) {
  const [isDebitedModalOpen, setDebitedModalOpen] = useState(false);

  const handleOpenDebitedModal = () => {
    setDebitedModalOpen(true);
  };

  const handleCloseDebitedModal = () => {
    setDebitedModalOpen(false);
  };

  const handleConfirmAndCloseAll = () => {
    setDebitedModalOpen(false);
    onClose(); // Закрываем также AcquiringModal
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        PaperProps={{
          sx: {
            px: 3,
            borderRadius: 3,
            textAlign: "center",
            minWidth: 500,
            overflow: "hidden",
          },
        }}>
        <Box sx={{ width: "90%", mx: "auto" }}>
          <Box sx={{ backgroundColor: "#D9D9D9", height: "50px", my: 1 }} />
          <Box sx={{ borderBottom: "1px solid #D9D9D9" }} />
        </Box>
        <Box sx={{ mx: 10 }}>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: "2px solid #D9D9D9",
                  p: 2,
                }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", mb: 1 }}>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/120px-Visa_2021.svg.png"
                    alt="Visa"
                    width="42"
                  />
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/120px-Visa_2021.svg.png"
                    alt="Mastercard"
                    width="42"
                  />
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/120px-Visa_2021.svg.png"
                    alt="MIR"
                    width="42"
                  />
                </Box>
                <TextField
                  label="Номер карты"
                  variant="filled"
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: 2,
                    },
                  }}
                />
                <Box sx={{ display: "flex", gap: 3, mt: 3 }}>
                  <TextField
                    label="MM / ГГ"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      sx: {
                        borderRadius: 2,
                      },
                    }}
                  />
                  <TextField
                    label="CVV / CVC"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      sx: {
                        borderRadius: 2,
                      },
                    }}
                  />
                </Box>
              </Box>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Сохранить карту для следующих платежей"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    fontWeight: 400,
                  },
                }}
              />
              <TextField
                InputProps={{
                  sx: {
                    borderRadius: 2,
                  },
                }}
                label="E-mail для квитанции"
                variant="filled"
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              flexDirection: "column",
              gap: 1,
              "& > :not(style) ~ :not(style)": {
                marginLeft: "0 !important",
              },
            }}>
            <Button
              variant="contained"
              onClick={handleOpenDebitedModal}
              sx={{
                width: "92%",
                borderRadius: 2,
                py: 1.5,
                background: "#026E8C",
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "none",
                boxShadow: "none",
              }}>
              Оплатить 3000.00 тг
            </Button>
            <Button
              variant="text"
              onClick={onClose}
              sx={{
                width: "100%",
                borderRadius: 2,
                pt: 1,
                mb: 3,
                color: "#00796b",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                boxShadow: "none",
                textDecoration: "underline",
              }}>
              Отказаться от оплаты
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <DebitedModal
        open={isDebitedModalOpen}
        onClose={handleCloseDebitedModal}
        onConfirm={handleConfirmAndCloseAll}
      />
    </>
  );
}

export default AcquiringModal;
