// MyAuctions.jsx
import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)": {
    "&:hover": {
      border: "1px solid var(--blue-btn, #3083FF)",
    },
  },
});

const ITEMS_PER_PAGE = 6;

const MyAuctions = () => {
  const [page, setPage] = React.useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const auctions = [
    {
      lot: "135467880",
      product: "Iphone 14 Pro 256gb Silver",
      date: "26.08.2024 09:45",
      bid: "34 500 тг",
    },
    {
      lot: "135467880",
      product: "Iphone 15 Pro 128gb Silver",
      date: "26.08.2024 09:45",
      bid: "34 500 тг",
    },
    {
      lot: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      bid: "34 500 тг",
    },
    {
      lot: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      bid: "34 500 тг",
    },
    {
      lot: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      bid: "34 500 тг",
    },
    {
      lot: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      bid: "34 500 тг",
    },
    {
      lot: "135467880",
      product: "Iphone 14 pro",
      date: "26.08.2024 09:45",
      bid: "34 500 тг",
    },
  ];

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedAuctions = auctions.slice(startIndex, endIndex);

  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 3 }}>
        Мои аукционы
      </Typography>
      <Table sx={{ minWidth: 650, my: 5 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                pl: 7,
                borderBottom: "1px solid #ABBED1",
              }}>
              № Лот
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                pl: 5,
                maxWidth: "160px",
                borderBottom: "1px solid #ABBED1",
              }}>
              Товар
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                pr: 5,
                maxnWidth: "100px",
                borderBottom: "1px solid #ABBED1",
              }}>
              Дата
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                pl: 0,
                borderBottom: "1px solid #ABBED1",
              }}>
              Ставка
            </TableCell>
            <TableCell
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                py: 1.5,
                pl: 8,
                pr: 2,
                borderBottom: "1px solid #ABBED1",
              }}>
              Статус
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedAuctions.map((auction, index) => (
            <TableRow
              key={index}
              sx={{ backgroundColor: index % 2 === 0 ? "transparent" : "#F5F7FA" }}>
              <TableCell sx={{ borderBottom: "none", py: 1.5 }}>
                <IconButton size="small" sx={{ color: "#3083FF" }}>
                  <AddIcon sx={{ fontSize: "16px", mr: 2 }} />
                </IconButton>
                {auction.lot}
              </TableCell>
              <TableCell sx={{ borderBottom: "none", pl: 5, maxWidth: "164px" }}>
                {auction.product}
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }}>{auction.date}</TableCell>
              <TableCell sx={{ borderBottom: "none", pl: 0 }}>{auction.bid}</TableCell>
              <TableCell sx={{ borderBottom: "none" }}></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
        <StyledPagination
          count={Math.ceil(auctions.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default MyAuctions;
