import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Container,
  FormControlLabel,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Register = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNextStep = () => {
    navigate("/register/personal-info");
  };

  return (
    <Container className="container">
      <Header />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          maxWidth: "460px",
          margin: "0 auto",
          my: 5,
        }}>
        <Stepper
          activeStep={0}
          alternativeLabel
          sx={{
            width: "80%",
            "& .MuiStep-root": {
              p: 0,
            },
            "& .MuiStepLabel-root .MuiStepLabel-label": {
              display: "none",
            },
            "& .MuiStepConnector-root": {
              top: "21px",
              left: "calc(-50% + 22px)",
              right: "calc(50% + 20px)",
            },
            "& .MuiStepIcon-root": {
              width: "44px",
              height: "44px",
              color: "#f0f2f5",
              borderRadius: "50%",
              border: "2px solid #ABBED1",
              background: "none",
              "&.Mui-active": {
                color: "transparent",
                background:
                  "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                border: "none",
              },
              "&.Mui-active .MuiStepIcon-text": {
                fill: "#fff",
              },
            },
            "& .MuiStepConnector-line": {
              borderColor: "#ABBED1",
              borderTopWidth: "2px",
              top: "50%",
            },
            "& .MuiStepIcon-text": {
              fill: "#ABBED1",
              fontSize: "12px",
              fontWeight: 600,
            },
          }}>
          {[1, 2, 3].map((label) => (
            <Step key={label}>
              <StepLabel StepIconProps={{ children: <Typography>{label}</Typography> }}></StepLabel>
            </Step>
          ))}
        </Stepper>
        <Typography variant="h4" sx={{ fontSize: "36px", my: 4, fontWeight: 600 }}>
          Регистрация
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "14px", mb: 4, fontWeight: 400 }}>
          Заполните персональные данные
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            Электронная почта
          </Typography>
          <TextField
            label="Электронная почта"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Телефон</Typography>
          <TextField
            label="Телефон"
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Пароль</Typography>
          <TextField
            label="Пароль"
            variant="outlined"
            fullWidth
            type={showPassword ? "text" : "password"}
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            Повторите пароль
          </Typography>
          <TextField
            label="Повторите пароль"
            variant="outlined"
            fullWidth
            type={showConfirmPassword ? "text" : "password"}
            sx={{
              marginBottom: "16px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showConfirmPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleNextStep}
            sx={{
              my: 3,
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              py: "12px",
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            Регистрация
          </Button>
          <FormControlLabel
            control={<Checkbox sx={{ mt: "-9px" }} />}
            label={
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Я прочитал условия и согласен с условиями
                <Box component="span" display="block">
                  <Link href="#" sx={{ color: "#0671E0", textDecoration: "none" }}>
                    Политики конфиденциальности
                  </Link>{" "}
                  и{" "}
                  <Link href="#" sx={{ color: "#0671E0", textDecoration: "none" }}>
                    условиями использования brama.kz
                  </Link>
                </Box>
              </Typography>
            }
            sx={{
              alignItems: "flex-start",
              "& .MuiFormControlLabel-label": {
                mt: "-6px",
              },
            }}
          />
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export default Register;
