import image from "../assets/images/card_img_6.png";

const Cards = [
  { title: "Iphone 15 pro max", price: "50 000 тг", time: "00дн : 2ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "52 000 тг", time: "00дн : 3ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "48 000 тг", time: "00дн : 1ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "55 000 тг", time: "00дн : 5ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "60 000 тг", time: "01дн : 4ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "53 000 тг", time: "00дн : 2ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "49 000 тг", time: "00дн : 6ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "51 000 тг", time: "00дн : 3ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "54 000 тг", time: "00дн : 4ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "57 000 тг", time: "00дн : 2ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "58 000 тг", time: "01дн : 1ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "62 000 тг", time: "00дн : 4ч : 00мин", image: image },
  { title: "Iphone 15 pro max", price: "45 000 тг", time: "00дн : 5ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "61 000 тг", time: "00дн : 3ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "56 000 тг", time: "00дн : 2ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "59 000 тг", time: "00дн : 6ч : 00мин", image: image },
  { title: "Iphone 15 pro max", price: "47 000 тг", time: "01дн : 2ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "63 000 тг", time: "00дн : 3ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "64 000 тг", time: "00дн : 1ч : 55мин", image: image },
  { title: "Iphone 15 pro max", price: "46 000 тг", time: "00дн : 5ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "65 000 тг", time: "00дн : 2ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "66 000 тг", time: "00дн : 4ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "67 000 тг", time: "01дн : 3ч : 35мин", image: image },
  { title: "Iphone 15 pro max", price: "68 000 тг", time: "00дн : 1ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "70 000 тг", time: "00дн : 2ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "71 000 тг", time: "00дн : 3ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "72 000 тг", time: "00дн : 4ч : 05мин", image: image },
  { title: "Iphone 15 pro max", price: "73 000 тг", time: "00дн : 2ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "75 000 тг", time: "00дн : 5ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "76 000 тг", time: "01дн : 1ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "77 000 тг", time: "00дн : 2ч : 55мин", image: image },
  { title: "Iphone 15 pro max", price: "78 000 тг", time: "00дн : 3ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "79 000 тг", time: "00дн : 4ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "80 000 тг", time: "00дн : 5ч : 35мин", image: image },
  { title: "Iphone 15 pro max", price: "65 000 тг", time: "00дн : 2ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "66 000 тг", time: "00дн : 4ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "50 000 тг", time: "00дн : 2ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "52 000 тг", time: "00дн : 3ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "48 000 тг", time: "00дн : 1ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "55 000 тг", time: "00дн : 5ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "60 000 тг", time: "01дн : 4ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "53 000 тг", time: "00дн : 2ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "49 000 тг", time: "00дн : 6ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "51 000 тг", time: "00дн : 3ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "54 000 тг", time: "00дн : 4ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "57 000 тг", time: "00дн : 2ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "58 000 тг", time: "01дн : 1ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "62 000 тг", time: "00дн : 4ч : 00мин", image: image },
  { title: "Iphone 15 pro max", price: "45 000 тг", time: "00дн : 5ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "61 000 тг", time: "00дн : 3ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "56 000 тг", time: "00дн : 2ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "59 000 тг", time: "00дн : 6ч : 00мин", image: image },
  { title: "Iphone 15 pro max", price: "47 000 тг", time: "01дн : 2ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "63 000 тг", time: "00дн : 3ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "64 000 тг", time: "00дн : 1ч : 55мин", image: image },
  { title: "Iphone 15 pro max", price: "46 000 тг", time: "00дн : 5ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "65 000 тг", time: "00дн : 2ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "66 000 тг", time: "00дн : 4ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "67 000 тг", time: "01дн : 3ч : 35мин", image: image },
  { title: "Iphone 15 pro max", price: "68 000 тг", time: "00дн : 1ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "70 000 тг", time: "00дн : 2ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "71 000 тг", time: "00дн : 3ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "72 000 тг", time: "00дн : 4ч : 05мин", image: image },
  { title: "Iphone 15 pro max", price: "73 000 тг", time: "00дн : 2ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "75 000 тг", time: "00дн : 5ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "76 000 тг", time: "01дн : 1ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "77 000 тг", time: "00дн : 2ч : 55мин", image: image },
  { title: "Iphone 15 pro max", price: "78 000 тг", time: "00дн : 3ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "79 000 тг", time: "00дн : 4ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "80 000 тг", time: "00дн : 5ч : 35мин", image: image },
  { title: "Iphone 15 pro max", price: "65 000 тг", time: "00дн : 2ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "66 000 тг", time: "00дн : 4ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "50 000 тг", time: "00дн : 2ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "52 000 тг", time: "00дн : 3ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "48 000 тг", time: "00дн : 1ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "55 000 тг", time: "00дн : 5ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "60 000 тг", time: "01дн : 4ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "53 000 тг", time: "00дн : 2ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "49 000 тг", time: "00дн : 6ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "51 000 тг", time: "00дн : 3ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "54 000 тг", time: "00дн : 4ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "57 000 тг", time: "00дн : 2ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "58 000 тг", time: "01дн : 1ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "62 000 тг", time: "00дн : 4ч : 00мин", image: image },
  { title: "Iphone 15 pro max", price: "45 000 тг", time: "00дн : 5ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "61 000 тг", time: "00дн : 3ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "56 000 тг", time: "00дн : 2ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "59 000 тг", time: "00дн : 6ч : 00мин", image: image },
  { title: "Iphone 15 pro max", price: "47 000 тг", time: "01дн : 2ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "63 000 тг", time: "00дн : 3ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "64 000 тг", time: "00дн : 1ч : 55мин", image: image },
  { title: "Iphone 15 pro max", price: "46 000 тг", time: "00дн : 5ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "65 000 тг", time: "00дн : 2ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "66 000 тг", time: "00дн : 4ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "67 000 тг", time: "01дн : 3ч : 35мин", image: image },
  { title: "Iphone 15 pro max", price: "68 000 тг", time: "00дн : 1ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "70 000 тг", time: "00дн : 2ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "71 000 тг", time: "00дн : 3ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "72 000 тг", time: "00дн : 4ч : 05мин", image: image },
  { title: "Iphone 15 pro max", price: "73 000 тг", time: "00дн : 2ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "75 000 тг", time: "00дн : 5ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "76 000 тг", time: "01дн : 1ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "77 000 тг", time: "00дн : 2ч : 55мин", image: image },
  { title: "Iphone 15 pro max", price: "78 000 тг", time: "00дн : 3ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "79 000 тг", time: "00дн : 4ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "80 000 тг", time: "00дн : 5ч : 35мин", image: image },
  { title: "Iphone 15 pro max", price: "65 000 тг", time: "00дн : 2ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "66 000 тг", time: "00дн : 4ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "50 000 тг", time: "00дн : 2ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "52 000 тг", time: "00дн : 3ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "48 000 тг", time: "00дн : 1ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "55 000 тг", time: "00дн : 5ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "60 000 тг", time: "01дн : 4ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "53 000 тг", time: "00дн : 2ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "49 000 тг", time: "00дн : 6ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "51 000 тг", time: "00дн : 3ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "54 000 тг", time: "00дн : 4ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "57 000 тг", time: "00дн : 2ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "58 000 тг", time: "01дн : 1ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "62 000 тг", time: "00дн : 4ч : 00мин", image: image },
  { title: "Iphone 15 pro max", price: "45 000 тг", time: "00дн : 5ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "61 000 тг", time: "00дн : 3ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "56 000 тг", time: "00дн : 2ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "59 000 тг", time: "00дн : 6ч : 00мин", image: image },
  { title: "Iphone 15 pro max", price: "47 000 тг", time: "01дн : 2ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "63 000 тг", time: "00дн : 3ч : 30мин", image: image },
  { title: "Iphone 15 pro max", price: "64 000 тг", time: "00дн : 1ч : 55мин", image: image },
  { title: "Iphone 15 pro max", price: "46 000 тг", time: "00дн : 5ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "65 000 тг", time: "00дн : 2ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "66 000 тг", time: "00дн : 4ч : 15мин", image: image },
  { title: "Iphone 15 pro max", price: "67 000 тг", time: "01дн : 3ч : 35мин", image: image },
  { title: "Iphone 15 pro max", price: "68 000 тг", time: "00дн : 1ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "70 000 тг", time: "00дн : 2ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "71 000 тг", time: "00дн : 3ч : 50мин", image: image },
  { title: "Iphone 15 pro max", price: "72 000 тг", time: "00дн : 4ч : 05мин", image: image },
  { title: "Iphone 15 pro max", price: "73 000 тг", time: "00дн : 2ч : 45мин", image: image },
  { title: "Iphone 15 pro max", price: "75 000 тг", time: "00дн : 5ч : 20мин", image: image },
  { title: "Iphone 15 pro max", price: "76 000 тг", time: "01дн : 1ч : 10мин", image: image },
  { title: "Iphone 15 pro max", price: "77 000 тг", time: "00дн : 2ч : 55мин", image: image },
  { title: "Iphone 15 pro max", price: "78 000 тг", time: "00дн : 3ч : 40мин", image: image },
  { title: "Iphone 15 pro max", price: "79 000 тг", time: "00дн : 4ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "80 000 тг", time: "00дн : 5ч : 35мин", image: image },
  { title: "Iphone 15 pro max", price: "65 000 тг", time: "00дн : 2ч : 25мин", image: image },
  { title: "Iphone 15 pro max", price: "66 000 тг", time: "00дн : 4ч : 15мин", image: image },
];

export default Cards;
