import React, { useState } from "react";
import { Box, Typography, TextField, Button, Container } from "@mui/material";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const PasswordRecovery = () => {
  const [emailSent, setEmailSent] = useState(false);

  const handlePasswordRecovery = () => {
    setEmailSent(true);
  };

  return (
    <Container className="container">
      <Header />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          maxWidth: "460px",
          margin: "0 auto",
          my: 5,
        }}>
        <Typography variant="h4" sx={{ fontSize: "36px", mb: 4, fontWeight: 600 }}>
          Вход
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
            Электронная почта
          </Typography>
          <TextField
            label="Электронная почта"
            variant="outlined"
            fullWidth
            defaultValue={emailSent ? "petrov@mail.ru" : ""}
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                background: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#89939E",
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px",
                fontWeight: 400,
                transform: "translate(14px, 12px) scale(1)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: 400,
                padding: "12px",
              },
            }}
            InputProps={{
              readOnly: emailSent,
            }}
          />
          {!emailSent ? (
            <Button
              variant="contained"
              fullWidth
              onClick={handlePasswordRecovery}
              sx={{
                my: 3,
                background:
                  "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                boxShadow: "none",
                borderRadius: "10px",
                py: "12px",
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "none",
              }}>
              Восстановить пароль
            </Button>
          ) : (
            <Typography sx={{ px: 1, my: 3, fontSize: "14px", textAlign: "center" }}>
              Ссылка для восстановления пароля отправлена на почту. Проверьте почту.
            </Typography>
          )}
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export default PasswordRecovery;
