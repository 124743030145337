// OrderDetail.js
import React from "react";
import { Box, Typography, Button, Paper, MenuItem, TextField, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "1px solid #89939E",
    backgroundColor: "transparent",
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const OrderDetail = ({ order, onBack }) => {
  const [status, setStatus] = React.useState("");

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <Paper sx={{ borderRadius: "10px", p: 3, boxShadow: "none", pb: 0 }}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={onBack}
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          color: "#3083FF",
          borderColor: "#3083FF",
          fontWeight: 400,
          mb: 3,
          "&:hover": {
            borderColor: "#3083FF",
            backgroundColor: "rgba(48, 131, 255, 0.08)",
          },
        }}>
        Назад
      </Button>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, mb: 3 }}>
        Заказы
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: 600, mb: 5 }}>
            Персональные данные
          </Typography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>
                Электронная почта
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
                test@gmail.com
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>
                Номер телефона
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>
                8 707 643 32 45
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Фамилия</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>Тестов</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Имя</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>Тест</Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: 600, mb: 5 }}>
            Адрес
          </Typography>
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} sm={12}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>
                Почтовый индекс
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>010000</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Страна</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>Казахстан</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Область</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>Астана</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Город</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>Астана</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Улица</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 3 }}>Кенесары</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Номер дома</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>40</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>
                Номер квартиры / офис
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>15</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: 600, mb: 5 }}>
            Информация о товаре
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: "12px", color: "#717171" }}>Товар</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 5 }}>
              iPhone 15 Pro 256Gb Silver
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#717171", mb: 0 }}>Цена</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 5 }}>350 500 тг</Typography>
            <Box>
              <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Статус</Typography>
              <TextField
                select
                value={status}
                onChange={handleStatusChange}
                variant="outlined"
                sx={{ ...textFieldStyles, width: "100%" }}
                displayEmpty
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value) => value || "Выбрать статус",
                }}>
                <MenuItem sx={{ fontWeight: 500, py: 1 }} value="Новый">
                  Новый
                </MenuItem>
                <MenuItem sx={{ fontWeight: 500, py: 1 }} value="Подтвержден">
                  Подтвержден
                </MenuItem>
                <MenuItem sx={{ fontWeight: 500, py: 1 }} value="Отправлен">
                  Отправлен
                </MenuItem>
                <MenuItem sx={{ fontWeight: 500, py: 1 }} value="Завершен">
                  Завершен
                </MenuItem>
              </TextField>
            </Box>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                background:
                  "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
                boxShadow: "none",
                borderRadius: "10px",
                p: "14px 34px",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                mt: 2,
              }}>
              Сохранить
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrderDetail;
