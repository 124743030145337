import React from "react";
import { Box, Typography, Grid, MenuItem, TextField } from "@mui/material";
import Money_Img from "../../assets/images/money.svg";
import Green_Img from "../../assets/images/wallet_green.svg";
import Blue_Img from "../../assets/images/wallet_blue.svg";
import Red_Img from "../../assets/images/wallet_red.svg";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: "#89939E",
    background: "#FFFFFF",
    "& fieldset": {
      borderColor: "#89939E",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
  minWidth: 250,
};

const stats = [
  { id: 1, label: "Поступило на кошелек", value: "456 000 тг", img: Green_Img },
  { id: 2, label: "Получено за участие в аукционах", value: "156 000 тг", img: Money_Img },
  { id: 3, label: "Осталось на кошельках", value: "256 000 тг", img: Blue_Img },
  { id: 4, label: "Потрачено с кошелька", value: "156 000 тг", img: Red_Img },
];

const MainAdmin = () => {
  const [period, setPeriod] = React.useState("");

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
        <TextField
          select
          value={period}
          onChange={handlePeriodChange}
          variant="outlined"
          sx={textFieldStyles}
          displayEmpty
          SelectProps={{
            displayEmpty: true,
            renderValue: (value) => value || "Выбрать период",
          }}>
          <MenuItem value="7 дней">7 дней</MenuItem>
          <MenuItem value="30 дней">30 дней</MenuItem>
          <MenuItem value="6 месяцев">6 месяцев</MenuItem>
        </TextField>
      </Box>
      <Grid container spacing={2}>
        {stats.map((stat) => (
          <Grid item xs={12} md={6} lg={3} key={stat.id}>
            <Box
              sx={{
                border: "1px solid #ABBED1",
                borderRadius: "20px",
                pt: 1,
                pb: 2,
                px: 3,
                background: "#FFF",
              }}>
              <Box sx={{ mt: 2 }}>
                <img src={stat.img} alt="" style={{ width: "74px" }} />
                <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 500, mt: 1 }}>
                  {stat.label}
                </Typography>
              </Box>
              <Typography variant="h6" sx={{ color: "#3083FF", fontSize: "36px", fontWeight: 600 }}>
                {stat.value}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MainAdmin;
