import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";

export default function LotsMobile({ isMobile, toggleDrawer }) {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ mb: { xs: 5, md: 3 } }}>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600 }}>
        Лоты
      </Typography>
      {isMobile && (
        <Box>
          <IconButton
            onClick={toggleDrawer(true)}
            aria-label="filter"
            sx={{
              padding: "12px",
              borderRadius: 3,
              color: "#3083FF",
              border: "1px solid #3083FF",
            }}>
            <TuneIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
