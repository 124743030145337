import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider, GlobalStyles } from "@mui/material";
import theme from "./components/Theme/Theme";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        body: {
          fontFamily: "Roboto, sans-serif",
          backgroundColor: "#FAFBFF",
        },
      }}
    />
    <App />
  </ThemeProvider>,
  document.getElementById("root"),
);
