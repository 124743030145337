import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const PaymentDelivery = () => {
  return (
    <Container className="container">
      <Header />
      <Box
        component="main"
        sx={{
          my: 6,
        }}>
        <Typography variant="h1" sx={{ fontSize: "36px", fontWeight: 600, mb: 5 }}>
          Оплата и доставка
        </Typography>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 3, fontWeight: 600 }}>
            Доставка
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            Мы осуществляем доставку по всей стране. Стоимость и сроки доставки зависят от вашего
            местоположения и выбранного способа доставки. При оформлении заказа вы можете выбрать
            удобный для вас вариант доставки из предложенных.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 3, fontWeight: 600 }}>
            Самовывоз
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            Вы также можете забрать свой заказ самостоятельно из нашего пункта самовывоза. Мы
            предоставляем подробную информацию о местоположении и режиме работы пункта самовывоза на
            странице описания товара.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 3, fontWeight: 600 }}>
            Оплата
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            Мы принимаем различные способы оплаты, включая банковские карты, электронные платежи и
            наличные при получении заказа. Выбор способа оплаты доступен на этапе оформления заказа.
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 3, fontWeight: 600 }}>
            Условия возврата
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            Если вам не подошел заказанный товар, вы можете вернуть его в течение 14 дней с момента
            получения. Для этого свяжитесь с нашим отделом обслуживания клиентов для получения
            инструкции по возврату товара.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export default PaymentDelivery;
