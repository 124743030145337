import React from "react";
import { Box, Typography, Link, Container } from "@mui/material";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Contacts = () => {
  return (
    <Container className="container">
      <Header />
      <Box
        component="main"
        sx={{
          my: 6,
        }}>
        <Typography variant="h1" sx={{ fontSize: "36px", fontWeight: 600, mb: 5 }}>
          Контакты
        </Typography>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", fontWeight: 600 }}>
            Контакт центр
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px", my: 2 }}>
            Телефон: <Link href="tel:87006543243">8 (700) 654 32 43</Link>
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            Электронная почта: support@mail.com
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            Адрес
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            РК, 010000, г. Астана, ул. Кенесары 40, 4этаж, офис 15
          </Typography>
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" sx={{ fontSize: "20px", mb: 2, fontWeight: 600 }}>
            Реквизиты компании
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            РК, 010000, г. Астана, ул. Кенесары 40, 4 этаж, офис 15
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            БИН 34567897654
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            ИИК KZ345678976545678876
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            АО "Евразийский банк"
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            БИК UUJKLJGHJKLKJ
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            КБЕ 15
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export default Contacts;
