import React from "react";
import { Container, Grid, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import LotGrid from "../../components/LotGrid/LotGrid";
import LotsMobile from "../../components/LotsMobile/LotsMobile";

export default function Lots() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Container className="container">
      <Header />
      <Grid container spacing={2} sx={{ mt: 5 }}>
        {!isMobile && <Sidebar />}
        <Grid item xs={12} md={9}>
          <LotsMobile isMobile={isMobile} toggleDrawer={toggleDrawer} />
          <LotGrid />
        </Grid>
      </Grid>
      <Footer />
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Sidebar />
      </Drawer>
    </Container>
  );
}
