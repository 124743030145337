import React, { useState } from "react";
import { Box, Typography, TextField, Button, Paper, MenuItem, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "1px solid #89939E",
    backgroundColor: "transparent",
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    py: "10px",
  },
};

const AddNewCategory = ({ handleBack }) => {
  const [categoryImage, setCategoryImage] = useState(null);
  const [category, setCategory] = useState("");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleCategoryImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCategoryImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleRemoveCategoryImage = () => {
    setCategoryImage(null);
  };

  return (
    <Paper sx={{ borderRadius: "10px", boxShadow: "none" }}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          color: "#3083FF",
          borderColor: "#3083FF",
          fontWeight: 400,
          "&:hover": {
            borderColor: "#3083FF",
            backgroundColor: "rgba(48, 131, 255, 0.08)",
          },
        }}>
        Назад
      </Button>
      <Typography variant="h4" sx={{ fontSize: "36px", fontWeight: 600, my: 3 }}>
        Категория
      </Typography>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Наименование</Typography>
            <TextField
              placeholder="Наименование"
              variant="outlined"
              fullWidth
              sx={textFieldStyles}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>Slag</Typography>
            <TextField placeholder="Slag" variant="outlined" fullWidth sx={textFieldStyles} />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: 1, color: "#717171" }}>
              Родительская категория
            </Typography>
            <TextField
              select
              value={category}
              onChange={handleCategoryChange}
              variant="outlined"
              sx={{ ...textFieldStyles, width: "100%" }}
              displayEmpty
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) => value || "Родительская категория",
              }}>
              <MenuItem value="Категория 1">Категория 1</MenuItem>
              <MenuItem value="Категория 2">Категория 2</MenuItem>
              <MenuItem value="Категория 3">Категория 3</MenuItem>
            </TextField>
          </Box>
        </Box>
        <Box sx={{ width: 360, display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            sx={{
              background:
                "var(--gradient-primary-default, linear-gradient(180deg, #3083FF 0%, #73ABFF 100%))",
              boxShadow: "none",
              borderRadius: "10px",
              p: "14px 34px",
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "none",
            }}>
            Опубликовать
          </Button>
          <Box sx={{ border: "1px solid #8C8C8C", gap: 2, p: 2, borderRadius: "5px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 500, mb: 2 }} variant="h6">
              Изображение
            </Typography>
            {!categoryImage ? (
              <Button
                variant="text"
                component="label"
                sx={{
                  p: 0,
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#3083FF",
                  textTransform: "none",
                }}>
                Задать изображение
                <input type="file" hidden onChange={handleCategoryImageChange} />
              </Button>
            ) : (
              <Box sx={{ position: "relative" }}>
                <img
                  src={categoryImage}
                  alt="Category"
                  style={{ width: "100%", borderRadius: "5px" }}
                />
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    borderRadius: "50%",
                  }}
                  onClick={handleRemoveCategoryImage}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default AddNewCategory;
