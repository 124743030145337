import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import {
  ArrowDropDown as ArrowDropDownIcon,
  MenuRounded as MenuRoundedIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "./HeaderUnauthorized.scss";
import DrawerHeader from "../DrawerHeader/DrawerHeader";
import Unauthorized from "../Unauthorized/Unauthorized";
import Authorized from "../Authorized/Authorized";

export default function HeaderUnauthorized() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();
  const isSmaller = useMediaQuery("(max-width:1200px)");

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navigateToMain = () => {
    navigate("/");
  };

  const navigateToTerms = () => {
    navigate("/terms");
  };

  const navigateToPayDeliv = () => {
    navigate("/payment-and-delivery");
  };

  const navigateToContacts = () => {
    navigate("/contacts");
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <Box sx={{ flexGrow: 1, alignItems: "center" }}>
      <AppBar position="static" sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
        <Toolbar className="toolbar">
          <IconButton
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2, ml: 0, display: { md: "none" }, color: "#3083FF", p: 0 }}>
            <MenuRoundedIcon sx={{ fontSize: "48px" }} />
          </IconButton>
          <Typography
            className="logo"
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={navigateToMain}
            sx={{
              mr: 2,
              display: { md: "flex" },
              color: "black",
              fontSize: "39px",
              fontWeight: 700,
              textDecoration: "none",
              cursor: "pointer",
            }}>
            brama.kz
          </Typography>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#"
              onClick={navigateToMain}
              sx={{
                p: 1,
                display: { md: "flex" },
                color: "black",
                fontSize: "14px",
                fontWeight: 400,
                textDecoration: "none",
                textAlign: "center",
                cursor: "pointer",
              }}>
              Главная
            </Typography>
            <Typography
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              disableElevation
              variant="h6"
              noWrap
              component="a"
              href="#"
              sx={{
                p: 1,
                color: "black",
                fontSize: "14px",
                fontWeight: 400,
                textDecoration: "none",
                display: "flex",
                cursor: "pointer",
              }}>
              Категории
              <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                <ArrowDropDownIcon sx={{ pb: "3px" }} />
              </Box>
            </Typography>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                sx: {
                  mt: 1.5,
                  ml: -1.5,
                  boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                  borderRadius: 2,
                  minWidth: 165,
                },
              }}>
              <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
                Iphones
              </MenuItem>
              <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
                Смартфоны
              </MenuItem>
              <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
                Телевизоры
              </MenuItem>
              <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
                Аудиотехника
              </MenuItem>
              <MenuItem sx={{ p: 1.5, fontSize: "14px", fontWeight: 400 }} onClick={handleClose}>
                Фототехника
              </MenuItem>
            </Menu>

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#"
              onClick={navigateToTerms}
              sx={{
                p: 1,
                display: { display: "flex" },
                color: "black",
                fontSize: "14px",
                fontWeight: 400,
                textDecoration: "none",
                textAlign: "center",
                cursor: "pointer",
              }}>
              Условия
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#"
              onClick={navigateToPayDeliv}
              sx={{
                p: 1,
                display: { md: "flex" },
                color: "black",
                fontSize: "14px",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
              }}>
              Оплата и доставка
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#"
              onClick={navigateToContacts}
              sx={{
                p: 1,
                display: { md: "flex" },
                color: "black",
                fontSize: "14px",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
              }}>
              Контакты
            </Typography>
          </Box>
          {/*   {isSmaller ? <Authorized /> : <Unauthorized />}   */}
          {/* <Unauthorized onUserIconClick={navigateToLogin} /> */}
          <Authorized />
        </Toolbar>
      </AppBar>
      <DrawerHeader
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorEl={anchorEl}
        open={open}
      />
    </Box>
  );
}
